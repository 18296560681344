import { faQuestion, faStar, faUser } from '@fortawesome/free-solid-svg-icons';
import { useLibAnalytics } from '@sparx/analytics';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import {
  clickedCurrentHomework,
  clickedMathsFAQs,
  clickedMathsParentsGuide,
  clickedMathsStudentLogin,
} from 'app/events';
import { Card } from 'components/card';
import { DidYouKnow } from 'components/did-you-know';
import { Header } from 'components/header';
import { Hero, HeroItem } from 'components/hero/hero';
import { LastLogin } from 'components/last-login/last-login';
import { Link } from 'components/link';
import { LinkCard } from 'components/link-card/link-card';
import { CurrentHomeworkCard } from 'components/maths/current-homework-card';
import { DeletedHomeworkCard } from 'components/maths/deleted-homework-card';
import { PreviousHomeworkCard } from 'components/maths/previous-homework-card';
import { UngeneratedPlaceholder } from 'components/maths/ungenerated-placeholder';
import { ThemeWrapper } from 'components/theme-wrapper/theme-wrapper';
import {
  useLastMathsLogin,
  useMathsPackages,
  useSession,
  useStudentRewards,
} from 'queries/queries';
import { Suspense, useMemo } from 'react';
import { useBoolFlag } from 'utils/feature-flags';
import { getPackagesCompletionSummary, homeworkPhrase } from 'utils/homework';
import { PackageStatus_PLACEHOLDER } from 'utils/packages';

import styles from './maths-view.module.css';
import { XPTooltip } from './xp-tooltip';

export const MathsView = () => {
  return (
    <ThemeWrapper theme="parentweb-maths">
      <Header to="/" />

      <Hero product={Product.SPARX_MATHS}>
        <HeroItem icon={faUser}>
          <StudentName />
        </HeroItem>
        <HeroItem label="Total XP">
          <StudentXP />
        </HeroItem>
        <HeroItem label="XP Level">
          <XPLevel />
        </HeroItem>
        <HeroItem label="Last login">
          <MathsLastLogin />
        </HeroItem>
      </Hero>

      <section className={styles.Container}>
        <div className={styles.Body}>
          <Suspense fallback="">
            <Packages />
          </Suspense>

          <div className={styles.BottomContainer}>
            <div className={styles.DidYouKnowContainer}>
              <Suspense fallback="">
                <DidYouKnow />
              </Suspense>
            </div>

            <div className={styles.LinksContainer}>
              <LinkCard
                icon={faStar}
                title="A parent's guide to Sparx Maths"
                destination="https://sparxmaths.com/parents"
                external
                event={clickedMathsParentsGuide()}
              />
              <LinkCard
                icon={faQuestion}
                title="FAQs"
                destination="/faqs/maths"
                event={clickedMathsFAQs()}
              />
              <LinkCard
                icon={faUser}
                title="Student login"
                destination="https://www.sparxmaths.uk/student"
                external={true}
                event={clickedMathsStudentLogin()}
              />
            </div>
          </div>
        </div>
      </section>
    </ThemeWrapper>
  );
};

const Packages = () => {
  const { data: session } = useSession({ suspense: true });
  const sendEvent = useLibAnalytics();
  const { data } = useMathsPackages({ suspense: true });
  const { newPackages, prevPackages } = useMemo(
    () => getPackagesCompletionSummary(data?.packages || [], 'homework'),
    [data?.packages],
  );

  const studentName = session?.student?.firstName;
  if (!studentName) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {newPackages.length > 0 ? (
        newPackages.map(pkg => {
          if (pkg.deletedTime) {
            return <DeletedHomeworkCard pkg={pkg} key={pkg.id} />;
          }
          if (pkg.status === PackageStatus_PLACEHOLDER) {
            return (
              <Card title={`Current ${homeworkPhrase(1, pkg.isPrimary, true)}`} key={pkg.id}>
                <div className={styles.UngeneratedPlaceholder}>
                  <UngeneratedPlaceholder pkg={pkg} firstName={studentName} />
                </div>
              </Card>
            );
          }
          return (
            <Link to={pkg.id} style={{ textDecoration: 'none' }} key={pkg.id}>
              <CurrentHomeworkCard
                pkg={pkg}
                onClick={() => sendEvent(clickedCurrentHomework(Product.SPARX_MATHS, pkg.id))}
              />
            </Link>
          );
        })
      ) : (
        <NoHomeworkCard current={true} />
      )}
      {prevPackages.length > 0 ? (
        <div className={styles.PreviousHomeworkContainer}>
          <PreviousHomeworkCard
            product={Product.SPARX_MATHS}
            packages={prevPackages}
            onFocusPackage={() => undefined}
          />
        </div>
      ) : (
        <NoHomeworkCard current={false} />
      )}
    </>
  );
};

const StudentName = () => {
  const { data: session } = useSession({ suspense: true });
  return <strong>{session?.student?.firstName}</strong>;
};

const StudentXP = () => {
  const { data: rewards } = useStudentRewards({ suspense: true });
  return <strong>{rewards?.xpPoints.toLocaleString()}</strong>;
};

const XPLevel = () => {
  const { data: rewards } = useStudentRewards({ suspense: true });
  const useNewXP = useBoolFlag('new-xp-system', false);
  return (
    <strong>
      {useNewXP ? rewards?.currentXPLevel?.level || 1 : rewards?.oldLevel || 0}
      <XPTooltip />
    </strong>
  );
};

const MathsLastLogin = () => {
  const { data: lastLogin } = useLastMathsLogin({ suspense: true });
  return <LastLogin timestamp={lastLogin} />;
};

const NoHomeworkCard = ({ current }: { current: boolean }) => (
  <div className={styles.NoHomeworkSection}>
    <h2>{current ? 'Current' : 'Previous'} homework</h2>
    <p>No {current ? 'current' : 'previous'} homework</p>
  </div>
);
