import { Product } from '@sparx/api/apis/sparx/types/product';
import { TextWithMaths } from '@sparx/text-with-maths';
import { Header } from 'components/header';
import { Hero } from 'components/hero/hero';
import { PackageHero } from 'components/hero/package-hero';
import { ProgressBar } from 'components/progress-bar';
import { ThemeWrapper } from 'components/theme-wrapper/theme-wrapper';
import { useMathsPackage } from 'queries/queries';
import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { getCompletionPercentage } from 'utils/homework';
import { GenericTask, mathsPackageToGeneric, mathsTaskToGeneric } from 'utils/packages';

import styles from './maths-package-view.module.css';

export const MathsPackageView = () => {
  const { packageId } = useParams();

  return (
    <ThemeWrapper theme="parentweb-maths">
      <Header to="/maths" />

      <Suspense
        fallback={
          <Hero product={Product.SPARX_MATHS} title="Due: ">
            <ProgressBar percentComplete={0} />
          </Hero>
        }
      >
        <MathsPackageHero packageId={packageId || ''} />
      </Suspense>

      <section className={styles.Container}>
        <div className={styles.Body}>
          <Suspense>
            <p className={styles.Text}>The Compulsory homework includes the following topics:</p>
            <MathTasks packageId={packageId || ''} />
          </Suspense>
        </div>
      </section>
    </ThemeWrapper>
  );
};

const MathsPackageHero = ({ packageId }: { packageId: string }) => {
  const { data } = useMathsPackage(packageId, { suspense: true });
  const pkg = mathsPackageToGeneric(data?.pkg);
  if (!pkg) {
    return <>Not found</>;
  }
  return <PackageHero product={Product.SPARX_MATHS} pkg={pkg} />;
};

const MathTasks = ({ packageId }: { packageId: string }) => {
  const { data } = useMathsPackage(packageId, { suspense: true });
  const tasks = data?.tasks.map(mathsTaskToGeneric) || [];
  return <Tasks tasks={tasks} />;
};

export const Tasks = ({ tasks }: { tasks: GenericTask[] }) => {
  return (
    <div className={styles.TasksContainer}>
      {tasks.map(task => (
        <div key={task.id} className={styles.Task}>
          <TextWithMaths text={task.title} className={styles.TaskTitle} />

          <div className={styles.TaskProgress}>
            <div className={styles.TaskProgressBar}>
              <ProgressBar percentComplete={getCompletionPercentage(task.completion)} />
            </div>
            <span className={styles.TaskPercentage}>
              {getCompletionPercentage(task.completion)}%
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
