import classNames from 'classnames';
import { FAQs, FAQsContent } from 'components/faqs';
import { Header } from 'components/header/header';
import { ThemeWrapper } from 'components/theme-wrapper/theme-wrapper';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import styles from './faqs-view.module.css';

interface FAQsViewProps {
  theme: string;
  faqs: FAQs;
  headerPath: string;
}

export const FAQsView = ({ theme, faqs, headerPath }: FAQsViewProps) => {
  const [searchParams] = useSearchParams();
  const focusedFaq = searchParams.get('open') || '';

  useEffect(() => {
    if (!focusedFaq) return;
    setTimeout(() => {
      const element = document.getElementById(focusedFaq);
      element?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }, [focusedFaq]);

  return (
    <ThemeWrapper theme={theme}>
      <Header to={headerPath} />

      <div className={classNames(styles.Container, styles.Body)}>
        <FAQsContent focusedFaq={focusedFaq} faqs={faqs} />
      </div>
    </ThemeWrapper>
  );
};
