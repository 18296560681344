import classNames from 'classnames';
import { Tooltip } from 'components/tooltip/tooltip';

import styles from './progress-bar.module.css';

interface Props {
  percentComplete: number;
  skinny?: boolean;
  tooltip?: string;
}

export const ProgressBar = ({ percentComplete, skinny = false, tooltip }: Props) => {
  const bar = (
    <div
      className={classNames(
        styles.Inner,
        percentComplete === 100 && styles.Complete,
        skinny && styles.Skinny,
      )}
      style={{ width: `${percentComplete}%` }}
    />
  );

  return (
    <div className={styles.Container}>
      {tooltip ? (
        <Tooltip className={styles.Tooltip} content={tooltip}>
          {bar}
        </Tooltip>
      ) : (
        bar
      )}
    </div>
  );
};
