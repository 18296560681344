import { faQuestion, faStar, faUser } from '@fortawesome/free-solid-svg-icons';
import {
  clickedReaderFAQs,
  clickedReaderParentsGuide,
  clickedReaderStudentLogin,
} from 'app/events';
import classNames from 'classnames';
import { LinkCard } from 'components/link-card/link-card';

import styles from './links.module.css';

export const Links = () => {
  return (
    <div className={classNames(styles.Card, styles.NoBorder)}>
      <LinkCard
        icon={faStar}
        title="A parent's guide to Sparx Reader"
        destination="https://sparxreader.com/parents"
        external
        event={clickedReaderParentsGuide()}
      />
      <LinkCard
        icon={faQuestion}
        title="FAQs"
        destination="/faqs/reader"
        event={clickedReaderFAQs()}
      />
      <LinkCard
        icon={faUser}
        title="Student login"
        external={true}
        destination="https://app.sparxreader.com"
        event={clickedReaderStudentLogin()}
      />
    </div>
  );
};
