import { Product } from '@sparx/api/apis/sparx/types/product';
import { ThemeWrapper } from 'components/theme-wrapper/theme-wrapper';
import {
  GenericPackageStats,
  LoadingStat,
  TwoColumnCard,
} from 'components/two-column-card/two-column-card';
import { useMathsPackages } from 'queries/queries';
import { useMemo } from 'react';
import { getPackagesCompletionSummary } from 'utils/homework';

export const MathsCard = () => {
  const { data, isLoading } = useMathsPackages();
  const { newPackages, prevPackages } = useMemo(
    () => getPackagesCompletionSummary(data?.packages || [], 'homework'),
    [data?.packages],
  );

  let content = <LoadingStat />;
  if (!isLoading && data) {
    content = <GenericPackageStats currentPackages={newPackages} previousPackages={prevPackages} />;
  }

  return (
    <ThemeWrapper theme="parentweb-maths">
      <TwoColumnCard product={Product.SPARX_MATHS} title="Sparx Maths" destination="/maths">
        {content}
      </TwoColumnCard>
    </ThemeWrapper>
  );
};
