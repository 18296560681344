import { analyticsProviderConfig, LibAnalyticsProvider } from '@sparx/analytics';
import { useSession } from 'queries/queries';
import { PropsWithChildren } from 'react';

import { anonymousUserID } from './anonymousUserID';

const interactionGatewayURL = window.settings?.INTERACTION_GATEWAY_API_URL || '';
const env = window.settings?.ENV_NAME || 'test2';

/** AnalyticsProvider wraps the @sparx/Analytics Provider and provides it with configuration. */
export const AnalyticsProvider = ({ children }: PropsWithChildren) => {
  const { data: session } = useSession();

  // If there isn't a session, create a random UUID for the user ID:
  const userID = session?.student?.studentID || anonymousUserID;

  const config: analyticsProviderConfig = {
    application: 'parentweb',
    schoolID: session?.student?.schoolID || 'NotSet',
    userID,
    sessionID: session?.session?.sessionID || 'NotSet',
    version: import.meta.env.VITE_REACT_APP_VERSION || 'NotSet',
    pumps: {
      interactionGateway: {
        URL: interactionGatewayURL,
      },
    },
    debugLogs: env !== 'prod',
  };

  return <LibAnalyticsProvider config={config}>{children}</LibAnalyticsProvider>;
};
