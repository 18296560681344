import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'components/card';
import { ProgressBar } from 'components/progress-bar';
import { format } from 'date-fns';
import { DATE_DISPLAY_FORMAT, getCompletionPercentage, homeworkPhrase } from 'utils/homework';
import { GenericPackage } from 'utils/packages';

import styles from './current-homework-card.module.css';

interface Props {
  pkg: GenericPackage;
  onClick?: () => void;
}

export const CurrentHomeworkCard = ({ pkg, onClick }: Props) => {
  const percentComplete = getCompletionPercentage(pkg.completion);
  return (
    <Card
      title={`Current ${homeworkPhrase(1, pkg.isPrimary, true)}`}
      actionIcon={<FontAwesomeIcon icon={faChevronRight} className={styles.Icon} />}
      onClickAction={onClick}
    >
      {pkg.endDate && (
        <p className={styles.DueDate}>Due: {format(pkg.endDate, DATE_DISPLAY_FORMAT)}</p>
      )}
      <div className={styles.Progress}>
        <ProgressBar percentComplete={percentComplete} />
        <p>{percentComplete}%</p>
      </div>
    </Card>
  );
};
