import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

import styles from './card.module.css';

interface Props {
  actionIcon?: React.ReactNode;
  extraContent?: React.ReactNode;
  onClickAction?: () => void;
  showExtraContent?: boolean;
  title: string;
}

export const Card: React.FC<PropsWithChildren<Props>> = ({
  actionIcon,
  children,
  extraContent,
  showExtraContent = false,
  onClickAction,
  title,
}) => (
  <article className={styles.Card}>
    <div
      className={classNames(styles.Main, {
        [styles.Clickable]: !!onClickAction,
        [styles.Expanded]: showExtraContent,
      })}
      onClick={() => onClickAction && onClickAction()}
    >
      <div className={styles.Content}>
        <h3 className={styles.Title}>{title}</h3>
        {children}
      </div>
      {actionIcon && <div className={styles.Action}>{actionIcon}</div>}
    </div>
    <AnimatePresence>
      {extraContent && showExtraContent && (
        <motion.div initial={{ height: 0 }} animate={{ height: 'auto' }} exit={{ height: 0 }}>
          {extraContent}
        </motion.div>
      )}
    </AnimatePresence>
  </article>
);
