import { v4 as uuid } from 'uuid';

/**
 * Create a temporary user id for the user to use when not logged in, persist in local storage
 */
const ANON_USER_ID_KEY = 'curriculum_portal_anon_user_id';
export let anonymousUserID: string = localStorage.getItem(ANON_USER_ID_KEY) || '';
if (!anonymousUserID) {
  anonymousUserID = `anon-${uuid()}`;
  localStorage.setItem(ANON_USER_ID_KEY, anonymousUserID);
}
