import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLibAnalytics } from '@sparx/analytics';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { expandedCurrentHomework } from 'app/events';
import classNames from 'classnames';
import { Card } from 'components/card';
import { compareDesc } from 'date-fns';
import { useSession } from 'queries/queries';
import { useState } from 'react';
import { getCompletionPercentage, homeworkPhrase } from 'utils/homework';
import { GenericPackage } from 'utils/packages';

import { PreviousHomeworkItem } from './previous-homework-item';
import styles from './styles.module.css';

interface Props {
  product: Product;
  packages: GenericPackage[];
  onFocusPackage: (packageID: string) => void;
}

export const PreviousHomeworkCard = ({ product, onFocusPackage, packages }: Props) => {
  const sendEvent = useLibAnalytics();
  const sortedPackages = packages.sort((a, b) => compareDesc(a.endDate, b.endDate));

  const completePercentages = sortedPackages.map(pkg => getCompletionPercentage(pkg.completion));
  const completeCount = completePercentages.filter(c => c === 100).length;

  const [expanded, setExpanded] = useState(localStorage.getItem(`${product}_expanded`) === 'true');

  const expandedPackages = (
    <div className={styles.ExtraContent} role="list" aria-label="package list">
      {sortedPackages.map(pkg => (
        <span role="listitem" key={pkg.id}>
          <PreviousHomeworkItem product={product} onFocusPackage={onFocusPackage} pkg={pkg} />
        </span>
      ))}
    </div>
  );

  const { data: session } = useSession();
  const isPrimary = Boolean(session?.student?.isPrimaryStudent);

  const onClick = () => {
    sendEvent(expandedCurrentHomework(product, !expanded));
    setExpanded(expanded => !expanded);
    localStorage.setItem(`${product}_expanded`, !expanded ? 'true' : 'false');
  };

  return (
    <Card
      title={`Previous ${homeworkPhrase(1, isPrimary, true)}`}
      extraContent={expandedPackages}
      showExtraContent={expanded}
      actionIcon={
        <FontAwesomeIcon
          icon={faCaretDown}
          className={classNames(styles.Icon, expanded && styles.Flip)}
        />
      }
      onClickAction={() => onClick()}
    >
      <div className={classNames(styles.Progress, styles.FlexRow)}>
        <p
          className={classNames(styles.CompleteCount, {
            [styles.Green]: completeCount === completePercentages.length,
          })}
        >
          {completeCount}/{completePercentages.length} Complete
        </p>
      </div>
    </Card>
  );
};
