import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLibAnalytics } from '@sparx/analytics';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { clickedProductCard } from 'app/events';
import classNames from 'classnames';
import { Link } from 'components/link';
import { format } from 'date-fns';
import React from 'react';
import { getCompletionPercentage } from 'utils/homework';
import { GenericPackage, isComplete } from 'utils/packages';
import { headerLookup, logoLookup } from 'utils/products';

import styles from './two-column-card.module.css';

interface TwoColumnCardProps {
  product: Product;
  title: string;
  destination: string;
}

export const TwoColumnCard = ({
  product,
  title,
  destination,
  children,
}: React.PropsWithChildren<TwoColumnCardProps>) => {
  const sendEvent = useLibAnalytics();

  return (
    <Link
      to={destination}
      className={styles.Link}
      onClick={() => sendEvent(clickedProductCard(title))}
    >
      <div className={styles.TwoColumnCard}>
        <div
          className={styles.Title}
          style={
            headerLookup[product] ? { backgroundImage: `url(${headerLookup[product]})` } : undefined
          }
        >
          <h2>{logoLookup[product] ? <img src={logoLookup[product]} alt={title} /> : title}</h2>
          <div>
            <span className={styles.SeeMore}>See more</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
        <div className={styles.Content}>{children}</div>
      </div>
    </Link>
  );
};

interface NumberStatProps {
  stat: string;
  title: string;
  subtitle: string;
  level?: 'good' | 'medium' | 'bad';
}

export const NumberStat = ({ stat, title, subtitle, level }: NumberStatProps) => {
  return (
    <div className={styles.Stat}>
      {title}
      <div
        className={classNames(styles.Statistic, {
          [styles.StatisticGood]: level == 'good',
          [styles.StatisticMedium]: level == 'medium',
          [styles.StatisticBad]: level == 'bad',
        })}
      >
        {stat}
      </div>
      {subtitle}
    </div>
  );
};

export const LoadingStat = () => (
  <div className={classNames(styles.Stat, styles.LoadingStat)}>
    <LoadingSpinner size="lg" />
  </div>
);

export const GenericPackageStats = ({
  currentPackages,
  previousPackages,
}: {
  currentPackages: GenericPackage[];
  previousPackages: GenericPackage[];
}) => {
  const previousComplete = previousPackages.filter(pkg => isComplete(pkg.completion)).length;

  // Take the first package that is ending as the 'current' homework.
  const currentHomework = currentPackages[0];

  const currentCompletion = getCompletionPercentage(currentHomework?.completion);
  return (
    <>
      {currentHomework ? (
        <NumberStat
          title="Current homework:"
          stat={`${Math.round(currentCompletion)}%`}
          subtitle={`due ${format(currentHomework.endDate, 'EEE do haaa')}`}
          level={currentCompletion < 100 ? 'medium' : 'good'}
        />
      ) : (
        <NoHomework current={true} />
      )}
      {previousPackages.length > 0 ? (
        <NumberStat
          title="Previous homework:"
          stat={`${previousComplete}/${previousPackages.length}`}
          subtitle="completed"
          level={
            previousPackages.length > 0 && previousComplete !== previousPackages.length
              ? 'medium'
              : 'good'
          }
        />
      ) : (
        <NoHomework current={false} />
      )}
    </>
  );
};

const NoHomework = ({ current }: { current: boolean }) => (
  <div className={classNames(styles.Stat, styles.NoHomeworkMessage)}>
    No {current ? 'current' : 'previous'} homework
  </div>
);
