import classNames from 'classnames';
import { useGetHomeworkMap } from 'queries/reader';

import { EmptyState } from '../reader-view';
import styles from './previous-homework.module.css';

export const PreviousHomework = () => {
  const { data: homeworkMap } = useGetHomeworkMap();

  const completeCount =
    homeworkMap?.previousHomeworks?.filter(hw => hw.experience >= hw.targetExperience).length ?? 0;
  const totalCount = homeworkMap?.previousHomeworks.length ?? 0;

  return (
    <div className={classNames(styles.Card, styles.PreviousHomework)}>
      <div className={styles.TitleContainer}>
        <p className={styles.CardTitle}>Previous homework</p>
      </div>
      <div>
        {totalCount > 0 ? (
          <>
            <p className={styles.HomeworkCompleteCount}>
              {completeCount}/{totalCount}
            </p>
            <div className={styles.PillContainer}>
              {homeworkMap?.previousHomeworks.map((homework, i) => (
                <div
                  key={i}
                  className={classNames(
                    styles.Pill,
                    homework.experience >= homework.targetExperience
                      ? styles.PillCorrect
                      : styles.PillIncorrect,
                  )}
                />
              ))}
            </div>
          </>
        ) : (
          <EmptyState>No previous homework</EmptyState>
        )}
      </div>
    </div>
  );
};
