// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/pwworker/v1/parentweb.proto" (package "sparx.pwworker.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { StudentRewards } from '../../../../sparxweb/swmsg/sparxweb';
import { GetStudentRewardsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { GetStudentLastLoginAtResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { GetStudentLastLoginAtRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { SetParentEmailSubscriptionStatusResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { SetParentEmailSubscriptionStatusRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { GetParentEmailSubscriptionStatusResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { GetParentEmailSubscriptionStatusRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { AssetsTokenResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { AssetsTokenRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { FeedbackNotificationResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { FeedbackNotificationRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { PackageData } from '../../packages/v1/spxpkg';
import { PackageDataRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { SessionDetailsResponse } from '../../../../sparxweb/swmsg/sparxweb';
import { SessionDetailsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Product } from '../../types/product';
/**
 * @generated from protobuf message sparx.pwworker.v1.GetStudentProductAccessRequest
 */
export interface GetStudentProductAccessRequest {}
/**
 * @generated from protobuf message sparx.pwworker.v1.GetStudentProductAccessResponse
 */
export interface GetStudentProductAccessResponse {
  /**
   * A list of the products the student can access.
   *
   * @generated from protobuf field: repeated sparx.types.Product products = 1;
   */
  products: Product[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentProductAccessRequest$Type extends MessageType<GetStudentProductAccessRequest> {
  constructor() {
    super('sparx.pwworker.v1.GetStudentProductAccessRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.pwworker.v1.GetStudentProductAccessRequest
 */
export const GetStudentProductAccessRequest =
  new GetStudentProductAccessRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentProductAccessResponse$Type extends MessageType<GetStudentProductAccessResponse> {
  constructor() {
    super('sparx.pwworker.v1.GetStudentProductAccessResponse', [
      {
        no: 1,
        name: 'products',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ['sparx.types.Product', Product],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.pwworker.v1.GetStudentProductAccessResponse
 */
export const GetStudentProductAccessResponse =
  new GetStudentProductAccessResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.pwworker.v1.ParentWeb
 */
export const ParentWeb = new ServiceType('sparx.pwworker.v1.ParentWeb', [
  {
    name: 'GetSessionDetails',
    options: {},
    I: SessionDetailsRequest,
    O: SessionDetailsResponse,
  },
  {
    name: 'GetPackageData',
    options: {},
    I: PackageDataRequest,
    O: PackageData,
  },
  {
    name: 'GetFeedbackNotification',
    options: {},
    I: FeedbackNotificationRequest,
    O: FeedbackNotificationResponse,
  },
  {
    name: 'GetAssetsToken',
    options: {},
    I: AssetsTokenRequest,
    O: AssetsTokenResponse,
  },
  {
    name: 'GetParentEmailSubscriptionStatus',
    options: {},
    I: GetParentEmailSubscriptionStatusRequest,
    O: GetParentEmailSubscriptionStatusResponse,
  },
  {
    name: 'SetParentEmailSubscriptionStatus',
    options: {},
    I: SetParentEmailSubscriptionStatusRequest,
    O: SetParentEmailSubscriptionStatusResponse,
  },
  {
    name: 'GetStudentLastLoginAt',
    options: {},
    I: GetStudentLastLoginAtRequest,
    O: GetStudentLastLoginAtResponse,
  },
  {
    name: 'GetStudentProductAccess',
    options: {},
    I: GetStudentProductAccessRequest,
    O: GetStudentProductAccessResponse,
  },
  {
    name: 'GetStudentRewards',
    options: {},
    I: GetStudentRewardsRequest,
    O: StudentRewards,
  },
]);
