import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport';
import { RpcError, RpcTransport, UnaryCall } from '@protobuf-ts/runtime-rpc';
import { ParentWebClient } from '@sparx/api/apis/sparx/pwworker/v1/parentweb.client';
import { ParentWebClient as ReaderParentWebClient } from '@sparx/api/apis/sparx/reading/parentweb/v1/parentweb.client';
import { ParentWebClient as ScienceParentWebClient } from '@sparx/api/apis/sparx/science/parentweb/v1/parentweb.client';
import { AccessTokenProvider, authInterceptor, extensionInterceptor } from '@sparx/grpcweb';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'queries/queries';

// getTokenSearchParameter will throw an error if the required search
// parameters (`school` and `token`) are not present.
export const getTokenSearchParameter = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token') || '';
  const school = searchParams.get('school') || '';

  if (!token || !school) {
    throw 'Invalid params';
  }

  return new URLSearchParams({ token, school }).toString();
};

let fetchedToken = false;
export const useTokenFetch = () =>
  useQuery(
    ['firsttoken'],
    () =>
      new Promise<Response>(() => {
        return;
      }),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );

const tokenFetcher = async () => {
  const params = getTokenSearchParameter();
  const promise = fetch(window.settings.STUDENT_API_URL + `/accesstoken_parent?${params}`, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      ...makeVariantHeader(),
    },
  });

  // Track that we have fetched the token for the first time
  if (!fetchedToken) {
    fetchedToken = true;
    promise.then(data => queryClient.setQueryData(['firsttoken'], data));
  }

  // Prevent endlessly retrying on authentication errors.
  promise.then(res => {
    if (res.status === 403) {
      accessTokenProvider.stop();
      console.log('Access Token Provider stopped');
    }
  });

  return promise;
};

// BELOW IS COPIED FROM SWCLIENT2 =============

// Configure the access token provider and set it to load periodically
const accessTokenProvider: AccessTokenProvider = new AccessTokenProvider(tokenFetcher);
accessTokenProvider.start().then(() => console.log('Access Token Provider initialised'));

const variantHeader = 'SPX-Variant-Selector';

const getVariantHeaderFromParams = (): string | null => {
  const params = new URLSearchParams(window?.location.search);
  const variant = params.get('spx.variant');
  return variant;
};

const makeVariantHeader = () => {
  const meta: Record<string, string> = {};
  const variant = getVariantHeaderFromParams();

  if (variant) {
    meta[variantHeader] = variant;
  }
  return meta;
};

export const getTransport = (
  url: string,
  excludeSessionId?: boolean,
  withServerOffset?: boolean,
): RpcTransport =>
  new GrpcWebFetchTransport({
    baseUrl: url,
    format: 'binary',
    meta: makeVariantHeader(),
    fetchInit: {
      credentials: 'include',
    },
    interceptors: [
      authInterceptor(accessTokenProvider, {
        getSessionID: excludeSessionId ? undefined : getSessionID,
        withServerOffset,
      }), // add auth to requests
      extensionInterceptor, // show requests in the gRPC-Web Chrome extension

      // Reload the page if the session is inactive
      {
        interceptUnary(next, method, input, options): UnaryCall {
          const response = next(method, input, options);

          response.response.catch(err => {
            if (err instanceof RpcError && err.message === 'SessionInactive') {
              console.log('session inactive, reloading page...');
              location.reload(); // TODO - we could block the UI and show a message here
            }
          });

          return response;
        },
      },
    ],
  });

// Record the time when Javascript loads, to calculate offset between client and server:
const dateAtLoad = new Date();
const performanceNowAtLoad = performance.now();

const getNow = () => {
  const millisSinceLoad = performance.now() - performanceNowAtLoad;
  return new Date(dateAtLoad.getTime() + millisSinceLoad);
};

export const useSessionError = () =>
  useQuery(['get-session-id'], () => getSessionID(), {
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: false,
  });

let sessionPromise: Promise<{ sessionID: string; serverOffset: number }> | undefined;
const getSessionID = async () => {
  if (!sessionPromise) {
    const params = getTokenSearchParameter();
    sessionPromise = fetch(window.settings.STUDENT_API_URL + `/clientsession?${params}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        // 'X-CSRF-Token': getCSRFCookie(),
        'Content-Type': 'application/json',
        ...makeVariantHeader(),
      },
    }).then(async response => {
      if (!response.ok) {
        throw await response.text();
      }
      const { sessionID, serverNow } = await response.json();

      // Work out the timing offset between client and server:
      const serverNowDate = new Date(serverNow);
      const clientNow = getNow();
      const serverOffset = Math.round((serverNowDate.getTime() - clientNow.getTime()) / 1000);

      return { sessionID, serverOffset };
    });
  }
  return sessionPromise;
};
// STOP SPARXWEB2 COPYING

export const pwworkerClient = new ParentWebClient(
  getTransport(window.settings.PWWORKER_API_URL, false, true),
);
export const scienceClient = new ScienceParentWebClient(
  getTransport(window.settings.SCIENCE_API_URL, true, false),
);
export const readerClient = new ReaderParentWebClient(
  getTransport(window.settings.READER_API_URL, true, false),
);
