import DidYouKnowDecoration from 'images/tips.svg';
import { FunctionComponent, useState } from 'react';
import { useBoolFlag } from 'utils/feature-flags';

import styles from './did-you-know.module.css';

const getMessages = (useNewXp: boolean) => [
  'Sparx Maths is personalised to help your child work on the topics they need to practise the most.',
  'Sparx Maths becomes more personalised for your child as we learn more about what they know.',
  'In maths exams, students will have to show written working. Sparx Maths is designed to encourage writing answers down!',
  'Every question in Sparx Maths has its own video so learners can help themselves if they get stuck.',
  'Each task in Sparx Maths builds up gradually to maximise learning.',
  'Sparx Maths questions build up step-by-step, so students can work through even the trickiest topics!',
  "Doing maths with a pen and paper helps students to work through every step of a problem and remember what they've learnt",
  'Sparx Maths is bespoke to your child, tailored to them and the areas that they are finding difficult.',
  'Bookwork checks help to encourage students to write their workings down, preparing them for future exams.',
  "All homework in Sparx Maths is set by your child's maths teacher. Teachers decide what topics will be set so it's always relevant!",
  "Your child's homework is made up of three parts: Compulsory, XP Boost and Target. XP Boost and Target are optional parts of the homework to allow students to practise their skills.",
  "If your child gets stuck on a question, they can watch the videos attached to each question. If they are still stuck, they can 'Seek help' from their teacher.",
  `Students earn XP points for every homework task they complete. They will reach a new Sparx Level every ${useNewXp ? '1,000' : '10,000'} XP they earn.`,
  'At Sparx we require 100% completion. As each homework is personalised for each student, this makes every homework achievable.',
  'Students use their workbooks to record their bookwork codes and answers. This is important to pass bookwork checks!',
  "It is important not to answer your child's questions for them. Sparx needs to know if a student is struggling in order to help them best.",
  "The Parent Portal page updates as your child completes their homework. Come back at any time during the week to see how they're doing!",
  'Sparx records how long students spend actively completing their homework so we can work out how many questions to give them next time.',
];

export const DidYouKnow: FunctionComponent = () => {
  const useNewXp = useBoolFlag('new-xp-system', false);
  const messages = getMessages(useNewXp);
  const [messageIndex] = useState(Math.floor(Math.random() * messages.length));

  return (
    <div className={styles.Container}>
      <div className={styles.TitleContainer}>
        <div className={styles.Title}>Did you know...</div>
        <img className={styles.Decoration} alt="" src={DidYouKnowDecoration} />
      </div>
      <div className={styles.Message}>{messages[messageIndex]}</div>
    </div>
  );
};
