import { StudentBookAndMetadata } from '@sparx/api/apis/sparx/reading/management/v1/management';
import { ProgressBar } from 'components/progress-bar';
import { getImageURL } from 'queries/reader';

import styles from './book-info.module.css';

export const BookCarousel = ({
  books,
  currentIndex,
  onOpenModal,
}: {
  books: StudentBookAndMetadata[] | undefined;
  currentIndex: number;
  onOpenModal: () => void;
}) => {
  if (!books || !books.length) {
    return null;
  }

  return (
    <div className={styles.BookCarousel}>
      <div style={{ left: `-${currentIndex * 100}%` }} className={styles.BookCarouselScroller}>
        {books?.map((book, i) => (
          <div key={i} className={styles.BookCarouselItem}>
            <BookInfo book={book} onOpenModal={onOpenModal} />
          </div>
        ))}
      </div>
    </div>
  );
};

export const BookInfo = ({
  book,
  onOpenModal,
}: {
  book: StudentBookAndMetadata;
  onOpenModal: () => void;
}) => {
  if (!book.metadata) {
    return null;
  }

  const percentComplete = (book.studentBook?.progress || 0) * 100;

  return (
    <div className={styles.Container}>
      {book.metadata?.coverImage && (
        <img
          src={getImageURL(book.metadata)}
          alt={`${book.metadata.title} book cover`}
          className={styles.Cover}
        />
      )}

      <div className={styles.Metadata}>
        <div style={{ flex: 1 }}>
          <h3>{book.metadata.title}</h3>
          <div className={styles.Author}>By {book.metadata.authors.join(', ')}</div>
          <div>
            <ProgressBar
              percentComplete={percentComplete}
              skinny={true}
              tooltip={`${Math.round(percentComplete)}% read`}
            />
          </div>
        </div>
        <span className={styles.SummaryLink} onClick={() => onOpenModal()}>
          Read a summary
        </span>
      </div>
    </div>
  );
};
