import { PackageCompletion, TaskCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { Completion } from '@sparx/api/apis/sparx/progress/v1/completion';
import { Homework } from '@sparx/api/apis/sparx/reading/tasks/v1/homework';
import { Package, Task } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { compareAsc, isBefore } from 'date-fns';

export interface GenericPackage {
  id: string;
  subject: 'maths' | 'reader' | 'science';
  completion: Completion | undefined;
  endDate: Date;
  deletedTime?: Date;
  isPrimary?: boolean;
  status?: string;
}

export const PackageStatus_PLACEHOLDER = 'HW_ON_DEMAND_PLACEHOLDER';

export interface GenericTask {
  id: string;
  title: string;
  completion: Completion | undefined;
}

export const mathsPackageToGeneric = (
  pkg: PackageCompletion | undefined,
): GenericPackage | undefined => {
  if (!pkg || !pkg.endDate) return undefined;
  return {
    id: pkg.packageID,
    subject: 'maths',
    completion: pkg.completion,
    endDate: Timestamp.toDate(pkg.endDate),
    deletedTime: pkg.deletedTime ? Timestamp.toDate(pkg.deletedTime) : undefined,
    status: pkg.status,
  };
};

export const mathsTaskToGeneric = (task: TaskCompletion): GenericTask => ({
  id: `${task.taskIndex}`,
  title: task.title,
  completion: task.completion,
});

export const sciencePackageToGeneric = (pkg: Package): GenericPackage | undefined => {
  if (!pkg.endTimestamp) return undefined;
  return {
    id: pkg.name,
    subject: 'science',
    completion: pkg.state?.completion,
    endDate: Timestamp.toDate(pkg.endTimestamp),
    deletedTime: pkg.cancelledTime ? Timestamp.toDate(pkg.cancelledTime) : undefined,
  };
};

export const scienceTaskToGeneric = (task: Task): GenericTask => ({
  id: task.name,
  title: task.title,
  completion: task.state?.completion,
});

export const readerPackageToGeneric = (pkg: Homework): GenericPackage | undefined => {
  if (!pkg.endDate) return undefined;
  return {
    id: pkg.homeworkId,
    subject: 'reader',
    completion: {
      weight: 1,
      size: pkg.targetExperience,
      progress: {
        C: pkg.experience,
      },
    },
    endDate: Timestamp.toDate(pkg.endDate),
  };
};

// Cancelled packages are never included in the previousPackages array, includeCancelledInCurrent can be set to include them in the current packages
export const groupSciencePackages = (pkgs: Package[], includeCancelledInCurrent: boolean) => {
  const currentPackages: GenericPackage[] = [];
  const previousPackages: GenericPackage[] = [];

  const now = new Date();
  for (const pkg of pkgs) {
    if (!pkg.endTimestamp || !pkg.startTimestamp) {
      continue;
    }

    const startDate = Timestamp.toDate(pkg.startTimestamp);
    const endDate = Timestamp.toDate(pkg.endTimestamp);
    const newPkg = sciencePackageToGeneric(pkg);
    if (!newPkg) continue; // ignore

    if (isBefore(startDate, now) && isBefore(now, endDate)) {
      if (!includeCancelledInCurrent && pkg.cancelledTime) continue;
      currentPackages.push(newPkg);
    } else {
      if (pkg.cancelledTime) continue;
      previousPackages.push(newPkg);
    }
  }
  return {
    currentPackages: currentPackages.sort((a, b) => compareAsc(a.endDate, b.endDate)),
    previousPackages,
  };
};

export const isComplete = (completion: Completion | undefined) =>
  completion && completion.size > 0 && (completion.progress['C'] || 0) >= completion.size;
