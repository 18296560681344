import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLibAnalytics } from '@sparx/analytics';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { previousHomeworkClicked } from 'app/events';
import classNames from 'classnames';
import { Link } from 'components/link';
import { ProgressBar } from 'components/progress-bar';
import { format } from 'date-fns';
import { useSession } from 'queries/queries';
import { DATE_DISPLAY_FORMAT, getCompletionPercentage } from 'utils/homework';
import { GenericPackage, PackageStatus_PLACEHOLDER } from 'utils/packages';
import { pathLookup } from 'utils/products';

import { UngeneratedPlaceholder } from '../ungenerated-placeholder';
import styles from './styles.module.css';

type Props = {
  product: Product;
  onFocusPackage: (packageID: string) => void;
  pkg: GenericPackage;
};

export const PreviousHomeworkItem = ({ product, onFocusPackage, pkg }: Props) => {
  const { data: session } = useSession();
  const sendEvent = useLibAnalytics();
  const completion = getCompletionPercentage(pkg.completion);

  const onClick = () => {
    sendEvent(previousHomeworkClicked(product, pkg.id));
    onFocusPackage(pkg.id);
  };

  const studentName = session?.student?.firstName;
  if (!studentName) {
    return <LoadingSpinner />;
  }

  if (pkg.status === PackageStatus_PLACEHOLDER) {
    return (
      <div className={styles.ItemContent}>
        <UngeneratedPlaceholder pkg={pkg} firstName={studentName} />
      </div>
    );
  }

  return (
    <Link
      to={`${pathLookup[product]}/${pkg.id.replace('packages/', '')}`}
      style={{ textDecoration: 'none' }}
    >
      <div className={classNames(styles.FlexRow, styles.Item)} onClick={() => onClick()}>
        <div className={styles.ItemContent}>
          {pkg.endDate && <p>Due: {format(pkg.endDate, DATE_DISPLAY_FORMAT)}</p>}
          <div className={classNames(styles.FlexRow, styles.Progress)}>
            <ProgressBar percentComplete={completion} />
            <p>{completion}%</p>
          </div>
        </div>
        <div className={styles.ItemAction}>
          <FontAwesomeIcon icon={faChevronRight} className={styles.Icon} />
        </div>
      </div>
    </Link>
  );
};
