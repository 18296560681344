import { format } from 'date-fns';
import { DATE_DISPLAY_FORMAT } from 'utils/homework';
import { GenericPackage } from 'utils/packages';

import styles from './ungenerated-placeholder.module.css';

type UngeneratedPlaceholderProps = {
  // The package that has yet to generate (status === PackageStatus_PLACEHOLDER).
  pkg: GenericPackage;
  // The student's first name.
  firstName: string;
};

export const UngeneratedPlaceholder = ({ pkg, firstName }: UngeneratedPlaceholderProps) => (
  <div>
    {pkg.endDate && (
      <p className={styles.Secondary}>Due: {format(pkg.endDate, DATE_DISPLAY_FORMAT)}</p>
    )}
    <p className={styles.Subtle}>
      {firstName} has not yet started this homework. Why not encourage them to get started?
    </p>
  </div>
);
