// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/parentweb/v1/parentweb.proto" (package "sparx.reading.parentweb.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { GetStudentBooksResponse } from '../../management/v1/management';
import { GetStudentBooksRequest } from '../../management/v1/management';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Homework } from '../../tasks/v1/homework';
import { User } from '../../users/v1/sessions';
/**
 * @generated from protobuf message sparx.reading.parentweb.v1.GetStudentInfoRequest
 */
export interface GetStudentInfoRequest {}
/**
 * @generated from protobuf message sparx.reading.parentweb.v1.GetStudentInfoResponse
 */
export interface GetStudentInfoResponse {
  /**
   * @generated from protobuf field: sparx.reading.users.v1.User user = 1;
   */
  user?: User;
}
/**
 * @generated from protobuf message sparx.reading.parentweb.v1.GetHomeworksRequest
 */
export interface GetHomeworksRequest {}
/**
 * @generated from protobuf message sparx.reading.parentweb.v1.GetHomeworksResponse
 */
export interface GetHomeworksResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.Homework homeworks = 1;
   */
  homeworks: Homework[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentInfoRequest$Type extends MessageType<GetStudentInfoRequest> {
  constructor() {
    super('sparx.reading.parentweb.v1.GetStudentInfoRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.parentweb.v1.GetStudentInfoRequest
 */
export const GetStudentInfoRequest = new GetStudentInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentInfoResponse$Type extends MessageType<GetStudentInfoResponse> {
  constructor() {
    super('sparx.reading.parentweb.v1.GetStudentInfoResponse', [
      { no: 1, name: 'user', kind: 'message', T: () => User },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.parentweb.v1.GetStudentInfoResponse
 */
export const GetStudentInfoResponse = new GetStudentInfoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworksRequest$Type extends MessageType<GetHomeworksRequest> {
  constructor() {
    super('sparx.reading.parentweb.v1.GetHomeworksRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.parentweb.v1.GetHomeworksRequest
 */
export const GetHomeworksRequest = new GetHomeworksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworksResponse$Type extends MessageType<GetHomeworksResponse> {
  constructor() {
    super('sparx.reading.parentweb.v1.GetHomeworksResponse', [
      {
        no: 1,
        name: 'homeworks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Homework,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.parentweb.v1.GetHomeworksResponse
 */
export const GetHomeworksResponse = new GetHomeworksResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.parentweb.v1.ParentWeb
 */
export const ParentWeb = new ServiceType(
  'sparx.reading.parentweb.v1.ParentWeb',
  [
    {
      name: 'GetStudentInfo',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'sw:parent',
          domain: 'sw',
        },
      },
      I: GetStudentInfoRequest,
      O: GetStudentInfoResponse,
    },
    {
      name: 'GetHomeworks',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'sw:parent',
          domain: 'sw',
        },
      },
      I: GetHomeworksRequest,
      O: GetHomeworksResponse,
    },
    {
      name: 'GetStudentBooks',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'sw:parent',
          domain: 'sw',
        },
      },
      I: GetStudentBooksRequest,
      O: GetStudentBooksResponse,
    },
  ],
);
