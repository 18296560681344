import { GetStudentProductAccessRequest } from '@sparx/api/apis/sparx/pwworker/v1/parentweb';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import {
  FeedbackNotificationRequest,
  GetStudentLastLoginAtRequest,
  PackageDataRequest,
  SessionDetailsRequest,
  SetParentEmailSubscriptionStatusRequest,
  StudentRewards,
} from '@sparx/api/sparxweb/swmsg/sparxweb';
import { QueryClient, useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { pwworkerClient } from 'api';

export const queryClient = new QueryClient();

interface Options {
  suspense?: boolean;
  staleTime?: number;
}

export const useSession = (opts?: Options) =>
  useQuery(
    ['session'],
    () =>
      pwworkerClient.getSessionDetails(SessionDetailsRequest.create({ timestamp: Timestamp.now() }))
        .response,
    opts,
  );

export const useProducts = (opts?: Options) =>
  useQuery(
    ['products'],
    () => pwworkerClient.getStudentProductAccess(GetStudentProductAccessRequest.create()).response,
    { select: data => data.products, ...(opts || {}) },
  );

export const useMathsPackages = (opts?: Options) =>
  useQuery(
    ['maths', 'packages'],
    () =>
      pwworkerClient.getPackageData(
        PackageDataRequest.create({
          getPackages: true,
          getTasks: true,
          getTaskItems: true,
          includeAllActivePackages: true,
        }),
      ).response,
    opts,
  );

export const useMathsPackage = (packageID: string, opts?: Options) =>
  useQuery(
    ['maths', 'packages', packageID],
    () =>
      pwworkerClient.getPackageData(
        PackageDataRequest.create({
          packageID,
          getPackages: true,
          getTasks: true,
          getTaskItems: true,
        }),
      ).response,
    {
      select: data => ({
        pkg: data.packages?.[0],
        tasks: data.tasks,
      }),
      ...opts,
    },
  );

export const useLastMathsLogin = (opts?: Options) =>
  useQuery(
    ['maths', 'lastlogin'],
    () => pwworkerClient.getStudentLastLoginAt(GetStudentLastLoginAtRequest.create({})).response,
    {
      select: data => data.lastLoginAt,
      ...opts,
    },
  );

export const useFeedbackNotifications = (req: FeedbackNotificationRequest) =>
  useQuery(['feedback-notifications'], () => pwworkerClient.getFeedbackNotification(req).response, {
    select: data => {
      const notifications = [];
      for (const respNotification of data.notifications || []) {
        let content;
        try {
          content = JSON.parse(respNotification.content);
        } catch (err) {
          content = {};
        }

        const notification = {
          ...respNotification,
          content,
        };
        notifications.push(notification);
      }
      return notifications;
    },
  });

const EMAIL_SUBSCRIPTION_KEY = 'get-email-subscription-status';

export const useParentEmailSubscriptionStatus = (parentID: string) =>
  useQuery(
    [EMAIL_SUBSCRIPTION_KEY],
    () => pwworkerClient.getParentEmailSubscriptionStatus({ parentID }).response,
    { enabled: Boolean(parentID) },
  );

export const useSetParentEmailSubscriptionStatus = () =>
  useMutation(
    (req: SetParentEmailSubscriptionStatusRequest) =>
      pwworkerClient.setParentEmailSubscriptionStatus(req).response,
    {
      onSuccess: resp => {
        queryClient.setQueryData([EMAIL_SUBSCRIPTION_KEY], resp);
      },
    },
  );

export const useStudentRewards = (options: UseQueryOptions<StudentRewards>) =>
  useQuery({
    queryKey: ['maths', 'studentRewards'],
    queryFn: async () => pwworkerClient.getStudentRewards({}).response,
    ...options,
  });
