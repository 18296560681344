import { Product } from '@sparx/api/apis/sparx/types/product';
import { ThemeWrapper } from 'components/theme-wrapper/theme-wrapper';
import {
  GenericPackageStats,
  LoadingStat,
  TwoColumnCard,
} from 'components/two-column-card/two-column-card';
import { useScienceSummary } from 'queries/science';
import { useMemo } from 'react';
import { groupSciencePackages } from 'utils/packages';

export const ScienceCard = () => {
  const { data, isLoading } = useScienceSummary();

  const { currentPackages, previousPackages } = useMemo(
    () => groupSciencePackages(data?.packages || [], false),
    [data?.packages],
  );

  let content = <LoadingStat />;
  if (!isLoading && data) {
    content = (
      <GenericPackageStats currentPackages={currentPackages} previousPackages={previousPackages} />
    );
  }

  return (
    <ThemeWrapper theme="parentweb-science">
      <TwoColumnCard product={Product.SPARX_SCIENCE} title="Sparx Science" destination="/science">
        {content}
      </TwoColumnCard>
    </ThemeWrapper>
  );
};
