import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { ProgressWheel } from '@sparx/sparx-design/components';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import classNames from 'classnames';
import { useGetHomeworkMap } from 'queries/reader';

import { EmptyState } from '../reader-view';
import styles from './card.module.css';

export const CurrentHomework = () => {
  const { data: homeworkMap, isLoading } = useGetHomeworkMap();

  const hw = homeworkMap?.currentHomework;

  return (
    <div className={classNames(styles.Card, styles.WideCard)}>
      <p className={styles.CardTitle}>Current homework</p>

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {!hw && <EmptyState>No current homework</EmptyState>}
          {hw && (
            <div className={styles.CurrentHomework}>
              <div className={styles.CurrentHomeworkGrid}>
                <div className={styles.CellTitle}>Due date:</div>
                <div className={styles.CellData}>
                  {hw.endDate &&
                    Timestamp.toDate(hw.endDate).toLocaleString(undefined, {
                      dateStyle: 'medium',
                    })}
                </div>
                <div>Completion:</div>
                <div className={styles.CellData}>
                  {hw.experience} / {hw.targetExperience} <small>SRP</small>
                </div>
              </div>
              <ProgressWheel
                completed={hw.experience}
                total={hw.targetExperience}
                asPercentage={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
