import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLibAnalytics } from '@sparx/analytics';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { clickedNextBook, clickedPreviousBook, openedBookSummaryModal } from 'app/events';
import classNames from 'classnames';
import { useGetStudentBooks } from 'queries/reader';
import { useState } from 'react';
import { BookCarousel } from 'views/reader-view/cards/book-info';

import { BookInfoDialog } from '../components/book-info-dialog';
import { EmptyState } from '../reader-view';
import styles from './card.module.css';

export const RecentlyReading = () => {
  const sendEvent = useLibAnalytics();
  const { data: books, isLoading } = useGetStudentBooks();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const currentBook = books?.[currentIndex];

  const nextBook = () => {
    const newIndex = Math.min(books?.length ? books.length - 1 : 0, currentIndex + 1);
    setCurrentIndex(newIndex);
    sendEvent(clickedNextBook(books?.[newIndex].metadata?.title || ''));
  };
  const previousBook = () => {
    const newIndex = Math.max(0, currentIndex - 1);
    setCurrentIndex(newIndex);
    sendEvent(clickedPreviousBook(books?.[newIndex].metadata?.title || ''));
  };
  const openModal = () => {
    setIsModalOpen(true);
    sendEvent(openedBookSummaryModal(currentBook?.metadata?.title || ''));
  };

  const isPreviousDisabled = !books?.length || currentIndex <= 0;
  const isNextDisabled = !books?.length || currentIndex >= books.length - 1;

  const navigator = (
    <div className={styles.Navigator}>
      <div
        className={classNames(
          styles.NavigateButton,
          isPreviousDisabled && styles.NavigateButtonDisabled,
        )}
        onClick={previousBook}
      >
        <FontAwesomeIcon icon={faCaretLeft} />
      </div>
      <div className={styles.NavigateState}>
        {currentIndex + 1} / {books?.length}
      </div>
      <div
        className={classNames(
          styles.NavigateButton,
          isNextDisabled && styles.NavigateButtonDisabled,
        )}
        onClick={nextBook}
      >
        <FontAwesomeIcon icon={faCaretRight} />
      </div>
    </div>
  );

  return (
    <div className={classNames(styles.Card, styles.WideCard)}>
      {Boolean(books?.length) && navigator}
      <h2 className={styles.CardTitle}>Recently reading</h2>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {!currentBook && <EmptyState>No previous reading history</EmptyState>}

          <BookCarousel books={books} currentIndex={currentIndex} onOpenModal={openModal} />

          {currentBook && (
            <BookInfoDialog
              open={isModalOpen}
              book={currentBook}
              currentIndex={currentIndex}
              bookCount={books?.length || 0}
              isPreviousDisabled={isPreviousDisabled}
              isNextDisabled={isNextDisabled}
              onClose={() => setIsModalOpen(false)}
              onPreviousBook={previousBook}
              onNextBook={nextBook}
            />
          )}
        </div>
      )}
    </div>
  );
};
