import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { Header } from 'components/header';
import { Hero, HeroItem } from 'components/hero/hero';
import { LastLogin } from 'components/last-login/last-login';
import { ThemeWrapper } from 'components/theme-wrapper/theme-wrapper';
import { useGetStudentInfo } from 'queries/reader';
import { SuspenseStudentName } from 'views/home-view/home-view';

import { CurrentHomework } from './cards/current-homework';
import { Links } from './cards/links';
import { PreviousHomework } from './cards/previous-homework';
import { RecentlyReading } from './cards/recently-reading';
import styles from './reader-view.module.css';

export const ReaderView = () => {
  const { data: user, isLoading } = useGetStudentInfo();

  return (
    <ThemeWrapper theme="parentweb-reader">
      <Header to="/" />

      <Hero product={Product.SPARX_READER}>
        <HeroItem icon={faUser}>
          <SuspenseStudentName />
        </HeroItem>
        <HeroItem label="Total SRP">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <strong>{user?.statistics?.srp.toLocaleString() || 0}</strong>
          )}
        </HeroItem>
        <HeroItem label="Last login">
          <LastReaderLogin />
        </HeroItem>
      </Hero>

      <section className={styles.Container}>
        <div className={styles.Body}>
          <div className={styles.CardContainer}>
            <CurrentHomework />
            <PreviousHomework />
            <RecentlyReading />
            <Links />
          </div>
        </div>
      </section>
    </ThemeWrapper>
  );
};

const LastReaderLogin = () => {
  const { data, isLoading } = useGetStudentInfo();
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return <LastLogin timestamp={data?.lastLogin} />;
};

export const EmptyState = ({ children }: React.PropsWithChildren) => (
  <div className={styles.EmptyState}>
    <p>{children}</p>
  </div>
);
