import { Product } from '@sparx/api/apis/sparx/types/product';
import { LargeLoading } from '@sparx/sparx-design/icons';
import { Header } from 'components/header';
import { ThemeWrapper } from 'components/theme-wrapper/theme-wrapper';
import { MathsCard } from 'components/two-column-card/maths-card';
import { ReaderCard } from 'components/two-column-card/reader-card';
import { ScienceCard } from 'components/two-column-card/science-card';
import { useProducts, useSession } from 'queries/queries';

import styles from './home-view.module.css';

export const HomeView = () => {
  const { data: products, isLoading } = useProducts();

  return (
    <ThemeWrapper theme="home">
      <Header />
      <div className={styles.Container}>
        <div className={styles.Body}>
          <h2 className={styles.StudentName}>
            <StudentName />
          </h2>
          <div className={styles.Cards}>
            {isLoading && <LargeLoading />}
            {!isLoading && products?.includes(Product.SPARX_MATHS) && <MathsCard />}
            {!isLoading && products?.includes(Product.SPARX_READER) && <ReaderCard />}
            {!isLoading && products?.includes(Product.SPARX_SCIENCE) && <ScienceCard />}
            {/*<LinkCard icon={faComment} title="Give feedback" destination="/feedback" />*/}
          </div>
        </div>
      </div>
    </ThemeWrapper>
  );
};

const StudentName = () => {
  const { data } = useSession();
  return <>{data?.student?.firstName}</>;
};

export const SuspenseStudentName = () => {
  const { data } = useSession({ suspense: true });
  return <>{data?.student?.firstName}</>;
};
