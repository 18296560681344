// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/parentweb/v1/parentweb.proto" (package "sparx.science.parentweb.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { ParentWeb } from './parentweb';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetSummaryResponse } from './parentweb';
import type { GetSummaryRequest } from './parentweb';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.parentweb.v1.ParentWeb
 */
export interface IParentWebClient {
  /**
   * @generated from protobuf rpc: GetSummary(sparx.science.parentweb.v1.GetSummaryRequest) returns (sparx.science.parentweb.v1.GetSummaryResponse);
   */
  getSummary(
    input: GetSummaryRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSummaryRequest, GetSummaryResponse>;
}
/**
 * @generated from protobuf service sparx.science.parentweb.v1.ParentWeb
 */
export class ParentWebClient implements IParentWebClient, ServiceInfo {
  typeName = ParentWeb.typeName;
  methods = ParentWeb.methods;
  options = ParentWeb.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetSummary(sparx.science.parentweb.v1.GetSummaryRequest) returns (sparx.science.parentweb.v1.GetSummaryResponse);
   */
  getSummary(
    input: GetSummaryRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSummaryRequest, GetSummaryResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSummaryRequest, GetSummaryResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
