import { Link as RouterLink, LinkProps, To, useSearchParams } from 'react-router-dom';

export const Link = (props: LinkProps) => {
  const [searchParams] = useSearchParams();
  // Extract the "to" prop in order to preserver the search params when linking.
  const { to, ...rest } = props;
  let toPath: To = to;
  if (typeof to === 'string') {
    toPath = {
      pathname: to,
      search: '?' + searchParams.toString(),
    };
  } else {
    toPath = {
      pathname: to.pathname,
      hash: to.hash,
      search: '?' + searchParams.toString() + to.search,
    };
  }

  return <RouterLink to={toPath} {...rest} />;
};
