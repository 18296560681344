import { faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLibAnalytics, UserInteractionEventFields } from '@sparx/analytics';
import { Link } from 'components/link';

import styles from './link-card.module.css';

interface LinkCardProps {
  icon: IconDefinition;
  title: string;
  destination: string;
  event: UserInteractionEventFields;
  external?: boolean;
}

export const LinkCard = ({ icon, title, destination, event, external }: LinkCardProps) => {
  const sendEvent = useLibAnalytics();

  const content = (
    <div className={styles.LinkCard}>
      <div className={styles.LeftContent}>
        <div className={styles.Icon}>
          <FontAwesomeIcon icon={icon} />
        </div>
        <h4 className={styles.Title}>{title}</h4>
      </div>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );

  return external ? (
    <a
      href={destination}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.LinkRef}
      onClick={() => sendEvent(event)}
    >
      {content}
    </a>
  ) : (
    <Link to={destination} className={styles.Link} onClick={() => sendEvent(event)}>
      {content}
    </Link>
  );
};
