// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/parentweb/v1/parentweb.proto" (package "sparx.reading.parentweb.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { ParentWeb } from './parentweb';
import type { GetStudentBooksResponse } from '../../management/v1/management';
import type { GetStudentBooksRequest } from '../../management/v1/management';
import type { GetHomeworksResponse } from './parentweb';
import type { GetHomeworksRequest } from './parentweb';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetStudentInfoResponse } from './parentweb';
import type { GetStudentInfoRequest } from './parentweb';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.reading.parentweb.v1.ParentWeb
 */
export interface IParentWebClient {
  /**
   * GetStudentInfo is for getting last login time, total experience, etc.
   *
   * @generated from protobuf rpc: GetStudentInfo(sparx.reading.parentweb.v1.GetStudentInfoRequest) returns (sparx.reading.parentweb.v1.GetStudentInfoResponse);
   */
  getStudentInfo(
    input: GetStudentInfoRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentInfoRequest, GetStudentInfoResponse>;
  /**
   * GetHomeworks gets the non-expired homeworks for a student.
   *
   * @generated from protobuf rpc: GetHomeworks(sparx.reading.parentweb.v1.GetHomeworksRequest) returns (sparx.reading.parentweb.v1.GetHomeworksResponse);
   */
  getHomeworks(
    input: GetHomeworksRequest,
    options?: RpcOptions,
  ): UnaryCall<GetHomeworksRequest, GetHomeworksResponse>;
  /**
   * GetStudentBooks gets the recent reading history for the student, with
   * their progress and the book metadata.
   *
   * @generated from protobuf rpc: GetStudentBooks(sparx.reading.management.v1.GetStudentBooksRequest) returns (sparx.reading.management.v1.GetStudentBooksResponse);
   */
  getStudentBooks(
    input: GetStudentBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentBooksRequest, GetStudentBooksResponse>;
}
/**
 * @generated from protobuf service sparx.reading.parentweb.v1.ParentWeb
 */
export class ParentWebClient implements IParentWebClient, ServiceInfo {
  typeName = ParentWeb.typeName;
  methods = ParentWeb.methods;
  options = ParentWeb.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * GetStudentInfo is for getting last login time, total experience, etc.
   *
   * @generated from protobuf rpc: GetStudentInfo(sparx.reading.parentweb.v1.GetStudentInfoRequest) returns (sparx.reading.parentweb.v1.GetStudentInfoResponse);
   */
  getStudentInfo(
    input: GetStudentInfoRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentInfoRequest, GetStudentInfoResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetStudentInfoRequest, GetStudentInfoResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * GetHomeworks gets the non-expired homeworks for a student.
   *
   * @generated from protobuf rpc: GetHomeworks(sparx.reading.parentweb.v1.GetHomeworksRequest) returns (sparx.reading.parentweb.v1.GetHomeworksResponse);
   */
  getHomeworks(
    input: GetHomeworksRequest,
    options?: RpcOptions,
  ): UnaryCall<GetHomeworksRequest, GetHomeworksResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetHomeworksRequest, GetHomeworksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * GetStudentBooks gets the recent reading history for the student, with
   * their progress and the book metadata.
   *
   * @generated from protobuf rpc: GetStudentBooks(sparx.reading.management.v1.GetStudentBooksRequest) returns (sparx.reading.management.v1.GetStudentBooksResponse);
   */
  getStudentBooks(
    input: GetStudentBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentBooksRequest, GetStudentBooksResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetStudentBooksRequest, GetStudentBooksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
