import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLibAnalytics } from '@sparx/analytics';
import { Button, Checkbox } from '@sparx/sparx-design/components';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { subscribeClicked, unsubscribeClicked } from 'app/events';
import { Header } from 'components/header/header';
import { ThemeWrapper } from 'components/theme-wrapper/theme-wrapper';
import {
  useParentEmailSubscriptionStatus,
  useSetParentEmailSubscriptionStatus,
} from 'queries/queries';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import styles from './unsubscribe-view.module.css';

const useCheckbox = (label: string) => {
  const [checked, setChecked] = useState(false);
  const checkbox = (
    <Checkbox
      id={label}
      label={label}
      checked={checked}
      onCheckedChange={checked => setChecked(checked === 'indeterminate' ? false : checked)}
    />
  );
  return { checkbox, checked };
};

export const UnsubscribeView = () => {
  const [searchParams] = useSearchParams();
  const parentID = searchParams.get('parentsubid') || '';
  const { data, isLoading, isError } = useParentEmailSubscriptionStatus(parentID);
  const subscribed = data?.subscribed;
  const [resubscribed, setResubscribed] = useState(false);
  const setSubscription = useSetParentEmailSubscriptionStatus();
  const sendEvent = useLibAnalytics();
  const tooManyEmailsCheckbox = useCheckbox('Receiving too many emails');
  const notInterestedCheckbox = useCheckbox('Not interested in this information');
  const notResponsibleForChildCheckbox = useCheckbox('I’m not responsible for this child');
  const otherCheckbox = useCheckbox('Other');

  const handleUnsubscribe = () => {
    if (!parentID) return;
    const checkboxStates = {
      tooManyEmails: tooManyEmailsCheckbox.checked,
      notInterested: notInterestedCheckbox.checked,
      notResponsibleForChild: notResponsibleForChildCheckbox.checked,
      other: otherCheckbox.checked,
    };
    sendEvent(unsubscribeClicked(checkboxStates));
    setSubscription.mutate({ parentID, subscribed: false });
  };

  const handleSubscribe = () => {
    if (!parentID) return;
    sendEvent(subscribeClicked());
    setResubscribed(true);
    setSubscription.mutate({ parentID, subscribed: true });
  };

  const subscribedView = (
    <>
      <strong>You are currently receiving homework emails from Sparx Learning. Here's why:</strong>
      <ul className={styles.List}>
        <li>You were signed up by your child&apos;s school</li>
        <li>You can see how much homework is left to do, if any</li>
        <li>You can see a record of what your child is achieving</li>
      </ul>
      <div className={styles.Line} />
      <strong>
        Still not interested? To help us improve our communications we&apos;d love to know why:
      </strong>
      <div className={styles.Checkboxes}>
        <>{tooManyEmailsCheckbox.checkbox}</>
        <>{notInterestedCheckbox.checkbox}</>
        <>{notResponsibleForChildCheckbox.checkbox}</>
        <>{otherCheckbox.checkbox}</>
      </div>
      <Button variant="contained" onClick={handleUnsubscribe}>
        Unsubscribe
      </Button>
    </>
  );

  const unsubscribedView = (
    <>
      <div className={styles.Icon}>
        <FontAwesomeIcon icon={faCheckCircle} className={styles.Icon} />
      </div>
      <h4 className={styles.containerHeader}>
        You have unsubscribed from receiving homework emails from Sparx Learning
      </h4>
      <ul>
        <li>
          If, at a later date, you wish to turn these emails back on, please contact your child’s
          school.
        </li>
        <li>
          Please note, if you are responsible for more than one child who is currently using Sparx
          you will also be unsubscribed from updates for those other children.
        </li>
      </ul>
      <h4 className={styles.Unsubscribed}>Unsubscribed by accident?</h4>
      <Button variant="contained" onClick={handleSubscribe}>
        Subscribe
      </Button>
    </>
  );

  const resubscribedView = (
    <h4 className={styles.containerHeader}>
      You have successfully resubscribed to homework emails from Sparx Learning
    </h4>
  );

  const unavailableView = (
    <h4 className={styles.containerHeader}>
      Unfortunately the page you are looking for is not available. Please contact the school's maths
      department for assistance.
    </h4>
  );

  const selectView = () => {
    if (!parentID) {
      return unavailableView;
    }
    if (isLoading) {
      return <LoadingSpinner size="lg" />;
    }
    if (subscribed === undefined || isError) {
      return unavailableView;
    }
    if (resubscribed) {
      return resubscribedView;
    }
    return subscribed ? subscribedView : unsubscribedView;
  };

  const currentView = selectView();

  return (
    <ThemeWrapper theme="parentweb-home">
      <Header to={'/'} />
      <div className={styles.Container}>
        <div className={styles.Body}>
          <div className={styles.Content}>
            <div className={styles.Card}>{currentView}</div>
          </div>
        </div>
      </div>
    </ThemeWrapper>
  );
};
