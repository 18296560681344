import { Product } from '@sparx/api/apis/sparx/types/product';
import classNames from 'classnames';
import { Header } from 'components/header';
import { Hero } from 'components/hero/hero';
import { PackageHero } from 'components/hero/package-hero';
import { ProgressBar } from 'components/progress-bar';
import { ThemeWrapper } from 'components/theme-wrapper/theme-wrapper';
import { useScienceSummary } from 'queries/science';
import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { sciencePackageToGeneric, scienceTaskToGeneric } from 'utils/packages';
import { Tasks } from 'views/maths-package-view/maths-package-view';
import styles from 'views/maths-package-view/maths-package-view.module.css';

export const SciencePackageView = () => {
  const { packageId } = useParams();

  const { data } = useScienceSummary();
  const pkg = data?.packages.find(pkg => pkg.name === `packages/${packageId}`);
  const genericPkg = pkg ? sciencePackageToGeneric(pkg) : undefined;
  const tasks = pkg?.contents?.tasks?.map(scienceTaskToGeneric) || [];

  return (
    <ThemeWrapper theme="parentweb-science">
      <Header to="/science" />

      {genericPkg && (
        <>
          <Suspense
            fallback={
              <Hero product={Product.SPARX_SCIENCE} title="Due: ">
                <ProgressBar percentComplete={0} />
              </Hero>
            }
          >
            <PackageHero product={Product.SPARX_SCIENCE} pkg={genericPkg} />
          </Suspense>

          <section className={classNames(styles.Container, styles.Body)}>
            <Tasks tasks={tasks} />
          </section>
        </>
      )}
    </ThemeWrapper>
  );
};
