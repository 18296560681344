// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/pwworker/v1/parentweb.proto" (package "sparx.pwworker.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { ParentWeb } from './parentweb';
import type { StudentRewards } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetStudentRewardsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetStudentProductAccessResponse } from './parentweb';
import type { GetStudentProductAccessRequest } from './parentweb';
import type { GetStudentLastLoginAtResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetStudentLastLoginAtRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { SetParentEmailSubscriptionStatusResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { SetParentEmailSubscriptionStatusRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetParentEmailSubscriptionStatusResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { GetParentEmailSubscriptionStatusRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { AssetsTokenResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { AssetsTokenRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { FeedbackNotificationResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { FeedbackNotificationRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { PackageData } from '../../packages/v1/spxpkg';
import type { PackageDataRequest } from '../../../../sparxweb/swmsg/sparxweb';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { SessionDetailsResponse } from '../../../../sparxweb/swmsg/sparxweb';
import type { SessionDetailsRequest } from '../../../../sparxweb/swmsg/sparxweb';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.pwworker.v1.ParentWeb
 */
export interface IParentWebClient {
  /**
   * @generated from protobuf rpc: GetSessionDetails(sparxweb.SessionDetailsRequest) returns (sparxweb.SessionDetailsResponse);
   */
  getSessionDetails(
    input: SessionDetailsRequest,
    options?: RpcOptions,
  ): UnaryCall<SessionDetailsRequest, SessionDetailsResponse>;
  /**
   * @generated from protobuf rpc: GetPackageData(sparxweb.PackageDataRequest) returns (sparx.packages.v1.PackageData);
   */
  getPackageData(
    input: PackageDataRequest,
    options?: RpcOptions,
  ): UnaryCall<PackageDataRequest, PackageData>;
  /**
   * @generated from protobuf rpc: GetFeedbackNotification(sparxweb.FeedbackNotificationRequest) returns (sparxweb.FeedbackNotificationResponse);
   */
  getFeedbackNotification(
    input: FeedbackNotificationRequest,
    options?: RpcOptions,
  ): UnaryCall<FeedbackNotificationRequest, FeedbackNotificationResponse>;
  /**
   * @generated from protobuf rpc: GetAssetsToken(sparxweb.AssetsTokenRequest) returns (sparxweb.AssetsTokenResponse);
   */
  getAssetsToken(
    input: AssetsTokenRequest,
    options?: RpcOptions,
  ): UnaryCall<AssetsTokenRequest, AssetsTokenResponse>;
  /**
   * @generated from protobuf rpc: GetParentEmailSubscriptionStatus(sparxweb.GetParentEmailSubscriptionStatusRequest) returns (sparxweb.GetParentEmailSubscriptionStatusResponse);
   */
  getParentEmailSubscriptionStatus(
    input: GetParentEmailSubscriptionStatusRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetParentEmailSubscriptionStatusRequest,
    GetParentEmailSubscriptionStatusResponse
  >;
  /**
   * @generated from protobuf rpc: SetParentEmailSubscriptionStatus(sparxweb.SetParentEmailSubscriptionStatusRequest) returns (sparxweb.SetParentEmailSubscriptionStatusResponse);
   */
  setParentEmailSubscriptionStatus(
    input: SetParentEmailSubscriptionStatusRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SetParentEmailSubscriptionStatusRequest,
    SetParentEmailSubscriptionStatusResponse
  >;
  /**
   * @generated from protobuf rpc: GetStudentLastLoginAt(sparxweb.GetStudentLastLoginAtRequest) returns (sparxweb.GetStudentLastLoginAtResponse);
   */
  getStudentLastLoginAt(
    input: GetStudentLastLoginAtRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentLastLoginAtRequest, GetStudentLastLoginAtResponse>;
  /**
   * GetStudentProductAccess returns which products the student can access.
   *
   * @generated from protobuf rpc: GetStudentProductAccess(sparx.pwworker.v1.GetStudentProductAccessRequest) returns (sparx.pwworker.v1.GetStudentProductAccessResponse);
   */
  getStudentProductAccess(
    input: GetStudentProductAccessRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentProductAccessRequest, GetStudentProductAccessResponse>;
  /**
   * @generated from protobuf rpc: GetStudentRewards(sparxweb.GetStudentRewardsRequest) returns (sparxweb.StudentRewards);
   */
  getStudentRewards(
    input: GetStudentRewardsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentRewardsRequest, StudentRewards>;
}
/**
 * @generated from protobuf service sparx.pwworker.v1.ParentWeb
 */
export class ParentWebClient implements IParentWebClient, ServiceInfo {
  typeName = ParentWeb.typeName;
  methods = ParentWeb.methods;
  options = ParentWeb.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetSessionDetails(sparxweb.SessionDetailsRequest) returns (sparxweb.SessionDetailsResponse);
   */
  getSessionDetails(
    input: SessionDetailsRequest,
    options?: RpcOptions,
  ): UnaryCall<SessionDetailsRequest, SessionDetailsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SessionDetailsRequest, SessionDetailsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetPackageData(sparxweb.PackageDataRequest) returns (sparx.packages.v1.PackageData);
   */
  getPackageData(
    input: PackageDataRequest,
    options?: RpcOptions,
  ): UnaryCall<PackageDataRequest, PackageData> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<PackageDataRequest, PackageData>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetFeedbackNotification(sparxweb.FeedbackNotificationRequest) returns (sparxweb.FeedbackNotificationResponse);
   */
  getFeedbackNotification(
    input: FeedbackNotificationRequest,
    options?: RpcOptions,
  ): UnaryCall<FeedbackNotificationRequest, FeedbackNotificationResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      FeedbackNotificationRequest,
      FeedbackNotificationResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetAssetsToken(sparxweb.AssetsTokenRequest) returns (sparxweb.AssetsTokenResponse);
   */
  getAssetsToken(
    input: AssetsTokenRequest,
    options?: RpcOptions,
  ): UnaryCall<AssetsTokenRequest, AssetsTokenResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<AssetsTokenRequest, AssetsTokenResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetParentEmailSubscriptionStatus(sparxweb.GetParentEmailSubscriptionStatusRequest) returns (sparxweb.GetParentEmailSubscriptionStatusResponse);
   */
  getParentEmailSubscriptionStatus(
    input: GetParentEmailSubscriptionStatusRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetParentEmailSubscriptionStatusRequest,
    GetParentEmailSubscriptionStatusResponse
  > {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetParentEmailSubscriptionStatusRequest,
      GetParentEmailSubscriptionStatusResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: SetParentEmailSubscriptionStatus(sparxweb.SetParentEmailSubscriptionStatusRequest) returns (sparxweb.SetParentEmailSubscriptionStatusResponse);
   */
  setParentEmailSubscriptionStatus(
    input: SetParentEmailSubscriptionStatusRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SetParentEmailSubscriptionStatusRequest,
    SetParentEmailSubscriptionStatusResponse
  > {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SetParentEmailSubscriptionStatusRequest,
      SetParentEmailSubscriptionStatusResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetStudentLastLoginAt(sparxweb.GetStudentLastLoginAtRequest) returns (sparxweb.GetStudentLastLoginAtResponse);
   */
  getStudentLastLoginAt(
    input: GetStudentLastLoginAtRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentLastLoginAtRequest, GetStudentLastLoginAtResponse> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetStudentLastLoginAtRequest,
      GetStudentLastLoginAtResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * GetStudentProductAccess returns which products the student can access.
   *
   * @generated from protobuf rpc: GetStudentProductAccess(sparx.pwworker.v1.GetStudentProductAccessRequest) returns (sparx.pwworker.v1.GetStudentProductAccessResponse);
   */
  getStudentProductAccess(
    input: GetStudentProductAccessRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetStudentProductAccessRequest,
    GetStudentProductAccessResponse
  > {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetStudentProductAccessRequest,
      GetStudentProductAccessResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetStudentRewards(sparxweb.GetStudentRewardsRequest) returns (sparxweb.StudentRewards);
   */
  getStudentRewards(
    input: GetStudentRewardsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentRewardsRequest, StudentRewards> {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetStudentRewardsRequest, StudentRewards>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
