import { Card } from 'components/card';
import { format } from 'date-fns';
import { useSession } from 'queries/queries';
import { DATE_DISPLAY_FORMAT, homeworkPhrase } from 'utils/homework';
import { GenericPackage } from 'utils/packages';

import styles from './deleted-homework-card.module.css';

interface Props {
  pkg: GenericPackage;
}

export const DeletedHomeworkCard = ({ pkg }: Props) => {
  const { data: session } = useSession();
  const isPrimary = Boolean(session?.student?.isPrimaryStudent);
  const firstName = session?.student?.firstName || '';
  const isMaths = pkg.subject === 'maths';

  return (
    <Card title={`${homeworkPhrase(1, isPrimary, true)} Cancelled`}>
      {pkg.endDate && (
        <p className={styles.DueDate}>Due: {format(pkg.endDate, DATE_DISPLAY_FORMAT)}</p>
      )}
      <div className={styles.Message}>
        {firstName}&apos;s current {isMaths && 'Compulsory'} {homeworkPhrase(1, isPrimary)} has been
        cancelled by their teacher and{' '}
        <span className={styles.Highlight}>does not need to be completed</span>.
      </div>
      {!isPrimary && (
        <div className={styles.Message}>
          {firstName} can still {isMaths && 'complete their XP Boost and Target homework or '}use
          Independent Learning.
        </div>
      )}
    </Card>
  );
};
