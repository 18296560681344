import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { differenceInHours, format, formatDistance } from 'date-fns';
import { useMemo } from 'react';

export const LastLogin = ({ timestamp }: { timestamp: Timestamp | undefined }) => {
  const lastLoginDate = useMemo(
    () => (timestamp ? Timestamp.toDate(timestamp) : undefined),
    [timestamp],
  );
  if (!lastLoginDate) {
    return <strong>Never</strong>;
  }
  const diff = differenceInHours(new Date(), lastLoginDate);
  if (diff < 24) {
    return <strong>{formatDistance(new Date(), lastLoginDate)} ago</strong>;
  }
  return <strong>{format(lastLoginDate, 'do MMM yyyy')}</strong>;
};
