import { Header } from 'components/header';
import { ThemeWrapper } from 'components/theme-wrapper/theme-wrapper';
import { ReactComponent as ErrorImage } from 'images/error.svg';
import { ReactComponent as NothingImage } from 'images/nothing.svg';

import styles from './error-page.module.css';

const ErrorPageContainer = ({ children }: React.PropsWithChildren) => (
  <ThemeWrapper theme="home">
    <div className={styles.FullPage}>
      <Header />
      <div className={styles.Container}>{children}</div>
    </div>
  </ThemeWrapper>
);

export const ErrorPage = () => (
  <ErrorPageContainer>
    <ErrorImage className={styles.Image} />
    <div className={styles.ErrorText}>
      <h1>Oops! Something went wrong.</h1>
      <p>Try checking back later.</p>
    </div>
  </ErrorPageContainer>
);

export const LinkExpired = () => (
  <ErrorPageContainer>
    <NothingImage className={styles.Image} />
    <div className={styles.ErrorText}>
      <h1>This link has expired</h1>
      <p>
        The information you are looking for is no longer available. Please check your most recent
        email from Sparx to view up-to-date information.
      </p>
    </div>
  </ErrorPageContainer>
);

export const NotAuthenticated = () => (
  <ErrorPageContainer>
    <NothingImage className={styles.Image} />
    <div className={styles.ErrorText}>
      <h1>This link is invalid</h1>
      <p>It may have expired, try following the link from your latest email or check back later.</p>
    </div>
  </ErrorPageContainer>
);
