import { isJsonObject, JsonObject, JsonValue, typeofJsonValue } from '@protobuf-ts/runtime';
import { Struct } from '@sparx/api/google/protobuf/struct';
import { useSession } from 'queries/queries';

type boolFeatureFlags = 'new-xp-system';

const isJsonValueBoolean = (val: JsonValue | undefined): val is boolean =>
  typeofJsonValue(val) === 'boolean';

const useFFValues = (): JsonObject | undefined => {
  const { data: { featureFlags } = {} } = useSession({ staleTime: 1000 * 60 * 5, suspense: true });
  if (featureFlags === undefined) {
    console.info('No feature flags loaded.');
    return undefined;
  }
  const jsonValue = Struct.toJson(featureFlags);
  if (jsonValue == null || !isJsonObject(jsonValue)) {
    console.error('Feature flags must be an object.');
    return undefined;
  }
  return jsonValue;
};

export const useBoolFlag = (flag: boolFeatureFlags, defaultValue: boolean): boolean => {
  const ffs = useFFValues();
  const val = ffs?.[flag];
  if (isJsonValueBoolean(val)) {
    return val;
  }
  return defaultValue;
};
