import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { PropsWithChildren, Suspense } from 'react';
import { headerLookup, logoLookup } from 'utils/products';

import styles from './hero.module.css';

interface HeroProps {
  product: Product;
  title?: string;
}

export const Hero = ({ product, title, children }: PropsWithChildren<HeroProps>) => {
  const backgroundImage = headerLookup[product];
  return (
    <section className={styles.Hero}>
      {backgroundImage && (
        <div className={styles.HeroImage} style={{ backgroundImage: `url(${backgroundImage})` }} />
      )}
      <div className={styles.Container}>
        <div className={styles.Body}>
          {title ? (
            <h2 className={styles.HeroTitle}>{title}</h2>
          ) : (
            <h2 className={styles.HeroTitle}>
              <img src={logoLookup[product]} alt={product.toString()} />
            </h2>
          )}
          <div className={styles.HeroItems}>{children}</div>
        </div>
      </div>
    </section>
  );
};

interface HeroItemProps {
  icon?: IconDefinition;
  label?: string;
}

export const HeroItem = ({ icon, label, children }: PropsWithChildren<HeroItemProps>) => (
  <div>
    {icon && <FontAwesomeIcon icon={icon} className={styles.HeroIcon} />}
    {label && <span className={styles.HeroLabel}>{label}:</span>}
    <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>
  </div>
);
