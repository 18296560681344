import '@radix-ui/themes/styles.css';

import { LargeLoading } from '@sparx/sparx-design/icons/index';
import { QueryClientProvider } from '@tanstack/react-query';
import { getTokenSearchParameter, useSessionError, useTokenFetch } from 'api';
import { CopyrightNotice } from 'components/copyright-notice';
import { ErrorPage, LinkExpired, NotAuthenticated } from 'components/error-page';
import { getSparxMathsFaqs, sparxReaderFaqs } from 'components/faqs/parentFAQs';
import { queryClient } from 'queries/queries';
import { PropsWithChildren, Suspense } from 'react';
import { createBrowserRouter, Outlet, RouterProvider, ScrollRestoration } from 'react-router-dom';
import { useBoolFlag } from 'utils/feature-flags';
import { FAQsView } from 'views/faqs-view/faqs-view';
import { HomeView } from 'views/home-view/home-view';
import { MathsPackageView } from 'views/maths-package-view/maths-package-view';
import { MathsView } from 'views/maths-view/maths-view';
import { ReaderView } from 'views/reader-view/reader-view';
import { SciencePackageView } from 'views/science-package-view/science-package-view';
import { ScienceView } from 'views/science-view/science-view';
import { UnsubscribeView } from 'views/unsubscribe-view/unsubscribe-view';

import { AnalyticsProvider } from './AnalyticsProvider/AnalyticsProvider';
import styles from './app.module.css';

const AppContent = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.AppContent}>{children}</div>
);

// Maths FAQs view is a wrapper around FAQsView that provides it with the ff dependent ff's
// can be removed once the `new-xp-system` flag is removed
const MathsFAQsView = () => {
  const useNewXP = useBoolFlag('new-xp-system', false);
  const faqs = getSparxMathsFaqs(useNewXP);
  return <FAQsView theme={'parentweb-maths'} faqs={faqs} headerPath="/maths" />;
};

const router = createBrowserRouter([
  {
    element: (
      <AppContent>
        <Outlet />
        <CopyrightNotice />
        <ScrollRestoration />
      </AppContent>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'faqs/maths',
        element: (
          <Suspense fallback="">
            <MathsFAQsView />
          </Suspense>
        ),
      },
      {
        path: '/',
        element: <HomeView />,
      },
      {
        path: 'maths',
        element: <MathsView />,
      },
      {
        path: 'maths/:packageId',
        element: <MathsPackageView />,
      },
      {
        path: 'reader',
        element: <ReaderView />,
      },
      {
        path: 'faqs/reader',
        element: (
          <FAQsView theme={'parentweb-reader'} faqs={sparxReaderFaqs} headerPath="/reader" />
        ),
      },
      {
        path: 'science',
        element: <ScienceView />,
      },
      {
        path: 'science/:packageId',
        element: <SciencePackageView />,
      },
      {
        path: 'unsubscribe',
        element: <UnsubscribeView />,
      },
    ],
  },
]);

export const App: React.FC = () => (
  <QueryClientProvider client={queryClient}>
    <AppLoading>
      <AnalyticsProvider>
        <RouterProvider router={router} />
      </AnalyticsProvider>
    </AppLoading>
  </QueryClientProvider>
);

const AppLoading = ({ children }: PropsWithChildren) => {
  // Wait for the access token to be fetched
  const { data: accessToken, isLoading } = useTokenFetch();
  // Check if pwworker returns an error getting the session.
  const { isError } = useSessionError();

  // Parent web requires both `token` and `school` query parameters to be set.
  try {
    getTokenSearchParameter();
  } catch (err) {
    return <NotAuthenticated />;
  }
  if ((!accessToken?.ok && !isLoading) || isError) {
    return <LinkExpired />;
  }
  if (isLoading) {
    return <LargeLoading />;
  }
  return <>{children}</>;
};
