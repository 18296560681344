import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'components/link';
import { Tooltip } from 'components/tooltip/tooltip';
import { useBoolFlag } from 'utils/feature-flags';

import styles from './xp-tooltip.module.css';

export const XPTooltip = () => {
  const useNewXP = useBoolFlag('new-xp-system', false);
  return (
    <Tooltip
      className={styles.Tooltip}
      content={
        <>
          <p>
            <strong>XP and XP Levels</strong>
          </p>
          <p>
            XP (Sparx Experience Points) is how your child is rewarded for completing their
            homework. They will gain XP by completing the Compulsory, XP Boost and Target tasks in
            their homework.
          </p>
          <p>
            They will reach a new XP Level at every {useNewXP ? '1,000' : '10,000'} XP to recognise
            their hard work.
          </p>
          <p>
            <Link to={{ pathname: '/faqs/maths', search: '&open=what-are-xp' }}>
              Find out more in the FAQs sections
            </Link>
          </p>
        </>
      }
    >
      <FontAwesomeIcon icon={faQuestionCircle} style={{ marginLeft: '8px' }} />
    </Tooltip>
  );
};
