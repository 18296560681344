import { Completion, PackageCompletion } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { isAfter, isBefore } from 'date-fns';

import { GenericPackage, mathsPackageToGeneric } from './packages';

export const DATE_DISPLAY_FORMAT = 'EEE do MMMM haaa';

export const homeworkPhrase = (
  number: number,
  isPrimary: boolean | undefined,
  capitalise?: boolean,
) => {
  if (isPrimary) {
    return `${capitalise ? 'A' : 'a'}ctivit${number === 1 ? 'y' : 'ies'}`;
  }
  return `${capitalise ? 'H' : 'h'}omework${number === 1 ? '' : 's'}`;
};

export const getCompletionPercentage = (completion: Completion | undefined) => {
  if (!completion || completion.size <= 0) {
    return 0;
  }

  const result = Math.round(((completion.progress['C'] || 0) / completion.size) * 100);

  return result > 100 ? 100 : result;
};

// Translated from: phoenix2/components/parent-portal/student-homework/student-homework.tsx:15
export const getPackagesCompletionSummary = (
  packages: PackageCompletion[],
  packageType: string,
) => {
  const newPackages: GenericPackage[] = [];
  const prevPackages: GenericPackage[] = [];

  const localNow = new Date();
  for (const pkg of packages) {
    // If the package doesn't match the required type, don't count it in the completion summary.
    if (pkg.packageType !== packageType || !pkg.startDate || !pkg.endDate) {
      continue;
    }

    const start = Timestamp.toDate(pkg.startDate);
    const end = Timestamp.toDate(pkg.endDate);

    const genericPkg = mathsPackageToGeneric(pkg);
    if (!genericPkg) {
      continue; // ignore
    }

    if (isBefore(localNow, end) && isAfter(localNow, start)) {
      newPackages.push(genericPkg);
    } else if (isBefore(end, localNow)) {
      // If the package has been deleted, don't count it in the previous statistics.
      if (pkg.deletedTime) {
        continue;
      }
      prevPackages.push(genericPkg);
    }
  }

  return { newPackages, prevPackages };
};
