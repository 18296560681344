import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLibAnalytics } from '@sparx/analytics';
import logo from '@sparx/sparx-design/logos/sparx_learning_logo.png';
import { clickedHeaderLogo } from 'app/events';
import classNames from 'classnames';
import { Link } from 'components/link';
import { useLocation } from 'react-router-dom';

import styles from './header.module.css';

type HeaderProps = { to?: string };

// If using the <Header /> component outside of the analytics or router
// contexts, it cannot be a link.
export const Header = ({ to }: HeaderProps) => (to ? <HeaderWithLink to={to} /> : <BaseHeader />);

// HeaderWithLink cannot be used outside of analytics or router contexts, e.g.
// on the error pages.
const HeaderWithLink = ({ to }: { to: string }) => {
  const sendEvent = useLibAnalytics();
  const location = useLocation();

  return <BaseHeader to={to} onClick={() => sendEvent(clickedHeaderLogo(location.pathname, to))} />;
};

// The base header can either take a `to` param AND `onClick` handler for sending
// analytics when the link is clicked, or neither.
type BaseHeaderProps =
  | Record<string, never>
  | {
      to: string;
      onClick: () => void;
    };

const BaseHeader = ({ to, onClick }: BaseHeaderProps) => (
  <div className={styles.Header}>
    <div className={classNames(styles.Container)}>
      {to ? (
        <Link to={to} onClick={onClick}>
          <div className={styles.LogoWithBack}>
            <div className={styles.BackButton}>
              <FontAwesomeIcon icon={faCaretLeft} />
            </div>
            <div className={styles.HeaderContainer}>
              <img alt="Sparx Learning" src={logo} className={styles.Logo} />
            </div>
          </div>
        </Link>
      ) : (
        <div className={styles.HeaderContainer}>
          <img alt="Sparx Learning" src={logo} className={styles.Logo} />
        </div>
      )}
    </div>
  </div>
);
