import { Homework } from '@sparx/api/apis/sparx/reading/tasks/v1/homework';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { ThemeWrapper } from 'components/theme-wrapper/theme-wrapper';
import {
  GenericPackageStats,
  LoadingStat,
  TwoColumnCard,
} from 'components/two-column-card/two-column-card';
import { useGetHomeworkMap } from 'queries/reader';
import { GenericPackage, readerPackageToGeneric } from 'utils/packages';

export const ReaderCard = () => {
  const { data: homeworkMap, isLoading } = useGetHomeworkMap();

  let content = <LoadingStat />;
  if (!isLoading && homeworkMap) {
    content = (
      <GenericPackageStats
        currentPackages={mapHomeworksToGeneric(
          homeworkMap.currentHomework ? [homeworkMap.currentHomework] : undefined,
        )}
        previousPackages={mapHomeworksToGeneric(homeworkMap.previousHomeworks)}
      />
    );
  }

  return (
    <ThemeWrapper theme="parentweb-reader">
      <TwoColumnCard product={Product.SPARX_READER} title="Sparx Reader" destination="/reader">
        {content}
      </TwoColumnCard>
    </ThemeWrapper>
  );
};

const mapHomeworksToGeneric = (hwks: Homework[] | undefined) => {
  const pkgs: GenericPackage[] = [];
  for (const hwk of hwks || []) {
    const genericPackage = readerPackageToGeneric(hwk);
    if (genericPackage) {
      pkgs.push(genericPackage);
    }
  }
  return pkgs;
};
