import { Product } from '@sparx/api/apis/sparx/types/product';
import classNames from 'classnames';
import { ProgressBar } from 'components/progress-bar';
import { format } from 'date-fns';
import { DATE_DISPLAY_FORMAT, getCompletionPercentage } from 'utils/homework';
import { GenericPackage } from 'utils/packages';

import { Hero } from './hero';
import styles from './hero.module.css';

type Props = {
  product: Product;
  pkg: GenericPackage;
};

export const PackageHero = ({ product, pkg }: Props) => {
  const endDate = pkg.endDate ? format(pkg.endDate, DATE_DISPLAY_FORMAT) : '';
  const completePercent = getCompletionPercentage(pkg.completion);

  return (
    <Hero product={product} title={`Due: ${endDate}`}>
      <div>
        <div className={styles.TaskProgress}>
          <div className={styles.TaskProgressBar}>
            <ProgressBar percentComplete={completePercent} />
          </div>
          <span className={classNames(styles.TaskPercentage, styles.White)}>
            {completePercent}%
          </span>
        </div>
      </div>
    </Hero>
  );
};
