export type FAQs = {
  infobox: {
    title: string;
    subtitle: string;
    header: string;
    tips: Tip[];
  };
  sections: Section[];
};

type Section = {
  questions: FAQ[];
  title: string;
};

type Tip = {
  header: string;
  content: string;
};

type FAQ = {
  id?: string;
  question: string;
  body: {
    answer?: string;
    video?: string;
    heading?: string;
  }[];
};

// This was directly copied from sparxweb/swclient/phoenix2/components/parent-portal/faqs/parentFAQs.ts
export const getSparxMathsFaqs = (useNewXp: boolean): FAQs => ({
  infobox: {
    title: 'FAQs for parents and carers',
    subtitle:
      'Success at school is always a partnership between teachers and parents, which is why we need your support.',
    header: 'Top tips for parents and carers',
    tips: [
      {
        header: 'Provide a computer or tablet in a quiet space.',
        content:
          'For up to two hours a week, please provide your child with an internet-connected computer or tablet, in a quiet setting, with space nearby to write in their workbook. Earphones or headphones are useful for concentration too. If this is not practical, please contact the school and they will endeavour to help.',
      },
      {
        header: 'Check their progress during the week.',
        content:
          'Please be aware of when your child’s homework is set and due each week and make sure they are on track to complete all of their Compulsory questions. Encourage them to start early each week so that they have time to ask their teacher if they get stuck.',
      },
    ],
  },

  sections: [
    {
      questions: [
        {
          question: 'What is Sparx Maths Homework?',
          body: [
            {
              answer:
                'Sparx Maths Homework is a system that helps schools to set and manage differentiated maths homework for every student. With Sparx, students get bespoke online homework each week – tailored to how they learn and the areas that they are finding difficult.',
            },
          ],
        },
        {
          question: 'How does it help my child?',
          body: [
            {
              answer:
                'With Sparx, your child’s homework is always at just the right level of difficulty for them. It’s not too easy and not too tough, so there’s less chance of them feeling overwhelmed or frustrated. Your child gets a unique flow of questions that helps them make maximum progress, and this feeling of progress, in turn, helps increase their motivation to learn.',
            },
          ],
        },
        {
          question: 'How much maths homework will my child get each week?',
          body: [
            {
              answer:
                'Sparx Maths Homework reviews the length of your child’s previous homework and adjusts the duration of their next homework to better match their pace, so the length of each homework assignment can vary slightly. All questions are accompanied by a support video in case your child gets stuck.',
            },
          ],
        },
        {
          question: 'Is a teacher still setting and marking homework, or is it all automated?',
          body: [
            {
              answer:
                'Sparx doesn’t try to replace teachers in setting homework, it just helps them set more personalised work. Teachers decide what topics will be set so that it’s always relevant. Students also write down all of their workings in a workbook, which teachers can collect and review each week.',
            },
          ],
        },
        {
          question: 'What kind of computer/tablet does my child need access to?',
          body: [
            {
              answer:
                'The majority of computers and tablets will be fine, including laptops, desktops, iPads and other tablets, but they do need to be connected to the internet. If you have both a tablet and a computer, your child might find it easier to use the tablet as it will leave more space for their workbook.',
            },
          ],
        },
        {
          question:
            'What if I can’t provide a computer/tablet and a working space as you’ve described?',
          body: [
            {
              answer: 'Please contact the school and they will endeavour to help you.',
            },
          ],
        },
      ],
      title: 'The basics',
    },
    {
      questions: [
        {
          question: 'If we can’t login, what do we do?',
          body: [
            {
              answer:
                'You should be able to access Sparx Maths Homework via the [login page](https://www.sparxmaths.uk/student) using your child’s username and password. If you can’t find your child’s login details, or your child needs to reset their password, then please contact your child’s maths teacher.',
            },
          ],
        },
        {
          question: 'How does my child use their workbook?',
          body: [
            {
              video: 'faqs/how_to_bookwork_004',
            },
            {
              answer:
                'Writing out working is vital for learning, which is why your child must write down their workings and answers in their workbook, as well as submitting their answers online.',
            },
            {
              answer:
                'At the start of each week’s homework, your child should draw a ruler line under the last piece of homework and then clearly write today’s date and underline it. They must have a margin on the page. If there’s no margin, they should draw one using a ruler.',
            },
            {
              answer:
                '__For each question your child is asked in their Sparx Maths Homework, they should do the following:__',
            },
            {
              answer: '- Write down the bookwork code of the question in the margin.',
            },
            {
              answer:
                '- Write down their working and the answer to the question next to the bookwork code on the page.',
            },
            { answer: '- Underline their answer.' },
            {
              answer: '- Submit their answer into Sparx Maths Homework to see if it is correct.',
            },
            {
              answer:
                '- Mark their answer with a tick or a cross, remembering to use their coloured pen for marking.',
            },
            {
              answer:
                '__If their answer is correct__, they should mark it with a coloured tick and then move on to the next question.',
            },
            {
              answer:
                '__If their answer is wrong__, they should mark it with a coloured cross and then have another try. For this second try, they should write down the bookwork code again in the margin, their working and answer on the page, and then submit their answer online.',
            },
            {
              answer:
                'If their second try is wrong, they should mark it with a coloured cross and then watch the support video for that question and have a third try – remembering to write down the bookwork code and their working and answer again in their workbook.',
            },
            {
              answer:
                'If their third try is also wrong, they should mark it with a coloured cross and then move on to the next question – but they should remember to ask their teacher for help when next in school. This is so that they are able to work out the correct answer before homework hand-in day.',
            },
            {
              answer:
                'Your child must take their workbook into school on homework hand-in day and hand it in in case their teacher wants to check it.',
            },
          ],
        },
        {
          question: 'What’s the difference between Compulsory, XP Boost and Target questions?',
          body: [
            {
              heading: 'Compulsory',
              answer:
                'Your child’s Compulsory homework contains questions around the topic(s) set by their teacher and topics that they’ve recently covered. The questions are tailored to their level of understanding.',
            },
            {
              heading: 'XP Boost',
              answer:
                "Your child's XP Boost allows them to earn loads of extra XP! XP Boost is tailored to their level and contains questions which will help your child to further strengthen their understanding.",
            },
            {
              heading: 'Target',
              answer:
                "Your child's Target homework contains questions which cover topics they have previously struggled with or an extension of topics that they have done well on. They might find these questions slightly more difficult than their Compulsory and XP Boost and require support from you or their teacher.",
            },
          ],
        },
        {
          question: 'What are bookwork checks?',
          body: [
            {
              answer:
                'It is vital that your child writes down their working. To make sure that they are, Sparx will occasionally ask your child to pass a bookwork check. A bookwork check presents your child with a bookwork code from a question they’ve recently answered, and then asks your child to enter the correct answer that they gave to that question. They must provide the correct answer that they wrote down for that bookwork code. The more bookwork checks your child passes, the less often they will be asked.',
            },
          ],
        },
        {
          question: 'What if my child gets stuck on a question?',
          body: [
            {
              answer:
                'Please encourage them to watch the support videos within Sparx Maths Homework. If they are still struggling with a question after having watched the video then they should ask their maths teacher for help.',
            },
            {
              answer:
                'Remember - they need to have answered every Compulsory question correctly before hand-in day.',
            },
          ],
        },
        {
          question: 'How can I check that my child has completed their homework?',
          body: [
            {
              answer:
                'Your child’s live homework summary will show you whether they have completed their Compulsory homework.',
            },
            {
              answer:
                'The live homework summary is accessible through the "View live homework summary" link in your most recent weekly email from Sparx about your child’s homework. If your child’s homework is complete, the "Current Homework" progress bar will be green and say "100% complete". The live homework summary will also show you whether your child needs to catch up on any previous Compulsory homework.',
            },
          ],
        },
        {
          question: 'What are the support videos for?',
          body: [
            {
              answer:
                'They are designed to take your child through a question or topic, step-by-step. Think of them as providing the same assistance that a teacher would do in class if your child was struggling with a question. There is a support video available for all of the questions in Sparx Maths Homework.',
            },
          ],
        },
        {
          question: 'Why do I receive a weekly email from Sparx?',
          body: [
            {
              answer:
                'Sparx sends you a weekly email that contains important information about your child’s homework. Please read this information to understand how your child is progressing with their homework and to see when their homework is due.',
            },
            {
              answer:
                "If you have any queries about these emails, please talk to your child's maths teacher.",
            },
          ],
        },
        {
          id: 'what-are-xp',
          question: 'What are XP (Sparx Experience Points)?',
          body: [
            {
              answer: `XP are how your child is rewarded for completing their homework. They will gain XP by completing the Compulsory, XP Boost and Target tasks in their homework. They will reach a new Sparx Level every ${useNewXp ? '1,000' : '10,000'} XP to recognise their hard work. Please congratulate your child when they reach a new level, and maybe reward them. The school may also offer rewards at each level, too.`,
            },
            {
              answer:
                'The fastest way for your child to reach their next level is by completing their XP Boost and Target tasks for each homework assignment, alongside their Compulsory tasks. XP Boost and Target tasks are trickier than Compulsory tasks, but will earn them more XP too.',
            },
            {
              answer:
                "- Compulsory homework:  420 XP (240 XP if your child's school uses Sparx Maths Classroom)",
            },
            {
              answer: '- XP Boost: 405 XP',
            },
            {
              answer: '- Target: 135 XP',
            },
          ],
        },
      ],
      title: 'Using Sparx Maths Homework',
    },
    {
      questions: [
        {
          question: 'What does my child need to have done before homework hand-in?',
          body: [
            {
              answer:
                'Your child is expected to have completed all the questions in their Compulsory homework by hand-in day, and get them all correct. That means they must have submitted correct answers for each question online so that all the tasks in that week’s Compulsory section are showing as "Completed" and are coloured green. They must also have written down all of their workings and answers in their workbook, and handed it in to be checked.',
            },
            {
              answer:
                "Please be aware of your child's homework set and hand-in days, and encourage them to start their homework early in the week. Doing this means that there is time for them to seek help if they get stuck on any questions.",
            },
          ],
        },
        {
          question: 'Will I know if my child is late with their homework?',
          body: [
            {
              answer:
                "Your child's live homework summary will show you whether they have completed their Compulsory homework.",
            },
            {
              answer:
                'The live homework summary is accessible through the "View live homework summary" link in your most recent weekly email from Sparx about your child’s homework. If your child’s homework is complete, the "Current Homework" progress bar will be green and say "100% complete". The live homework summary will also show you whether your child needs to catch up on any previous Compulsory homework.',
            },
          ],
        },
        {
          question: 'What happens if my child is unwell/off school?',
          body: [
            {
              answer:
                "You must tell the school and your child may be given extra time to complete that week's Compulsory homework. If they were to be given this extra time, they would still have to complete their new homework assignment on time each week.",
            },
            {
              answer:
                "Note: all Compulsory homework set during the year must be completed. Any homework that isn't completed due to illness or absence will have to be completed as soon as possible. If you have any concerns, please talk to your child’s maths teacher.",
            },
          ],
        },
      ],
      title: 'Late or missing homework',
    },
  ],
});

// This was directly copied from sparxweb/swclient/phoenix2/components/parent-portal/faqs/parentFAQs.ts
export const sparxReaderFaqs: FAQs = {
  infobox: {
    title: 'FAQs for parents and carers',
    subtitle:
      'Success at school is always a partnership between teachers and parents, which is why we need your support.',
    header: 'Top tips for parents and carers',
    tips: [
      {
        header: 'Provide a computer or tablet in a quiet place',
        content:
          'Please provide your child with an internet-connected computer or tablet, in a quiet setting. If this is not practical, please contact the school and they will endeavour to help.',
      },
      {
        header: 'Check their progress during the week',
        content:
          "Please be aware of when your child's homework is set and due each week and make sure they are on track to complete all of their reading. Encourage them to start early each week so that they have time to complete it before the deadline.",
      },
    ],
  },
  sections: [
    {
      questions: [
        {
          question: 'What is Sparx Reader?',
          body: [
            {
              answer:
                'Sparx Reader is a reading programme that gets all young people reading regularly, with accuracy and close attention.',
            },
          ],
        },
        {
          question: 'What are SRP? (Sparx Reader Points)',
          body: [
            {
              answer:
                'When teachers set homework, they will choose how many Sparx Reader Points a student will need to earn before completing their homework.',
            },
            {
              answer:
                'When students have read a section of the text, they are shown a small number of questions to check they have read carefully. If they pass the check by answering most of the questions correctly, they will earn some SRP.',
            },
            {
              answer:
                'If they do not pass the check, they will have to re-read the section of text. If they then pass the check, they will earn a smaller amount of SRP. The best way to earn SRP is to read carefully and pass the check on the first attempt.',
            },
          ],
        },
        {
          question: 'How does it help my child?',
          body: [
            {
              answer:
                'Sparx Reader gives each student a choice of books to read. The choice of books is tailored to their reading level. As they read, they’ll be asked questions about what they’ve read. The questions are easy for them to answer if they’ve read the book carefully, but harder if they haven’t.',
            },
          ],
        },
        {
          question: 'Is a teacher still setting and marking homework, or is it all automated?',
          body: [
            {
              answer:
                "Sparx doesn't try to replace teachers in setting homework, it just helps them to set more personalised work. Teachers decide how much reading students will need to do, and can see how long they have spent reading, as well as how easy or difficult they found the questions.",
            },
          ],
        },
        {
          question: 'What kind of computer/tablet does my child need access to?',
          body: [
            {
              answer:
                'The majority of computers and tablets will be fine, including laptops, desktops, iPads and other tablets, but they do need to be connected to the internet.',
            },
          ],
        },
        {
          question:
            "What if I can't provide a computer/tablet and a working space as you've described?",
          body: [
            {
              answer: 'Please contact the school and they will endeavour to help you.',
            },
          ],
        },
      ],
      title: 'The basics',
    },
    {
      questions: [
        {
          question: "If we can't login, what do we do?",
          body: [
            {
              answer:
                "You should be able to access Sparx Reader via the [login page](https://www.sparxreader.com/login) using your child's username and password. If you can't find your child's login details, or your child needs to reset their password, then please contact your child's school.",
            },
          ],
        },
        {
          question: 'Why is it taking a long time for my child to complete their homework?',
          body: [
            {
              answer:
                'If your child is reading carefully, they will be more likely to answer the questions correctly at the first attempt, and will therefore be required to answer fewer questions correctly in order to earn their SRP and move through the book. If your child is reading too quickly they will be required to answer every question correctly before they can progress.',
            },
          ],
        },
        {
          question: 'How do I support my child with their reading?',
          body: [
            {
              answer:
                "If your child is not enjoying a book or finding it too difficult, they can use the 'rate this book' page that appears after question checks. By inputting 'much too difficult', the student will be offered to swap the book for an easier title.",
            },
          ],
        },
      ],
      title: 'Using Sparx Reader',
    },
    {
      questions: [
        {
          question: 'What does my child need to have done before homework hand-in?',
          body: [
            {
              answer:
                'Your child is expected to have completed all of the reading their teacher has set them by hand-in day. This means they will need to have earned the SRP that the teacher has set for the homework.',
            },
          ],
        },
        {
          question: 'Will I know if my child is late with their homework?',
          body: [
            {
              answer:
                'The live homework summary is accessible through the ‘View live homework summary’ link in your most recent weekly email from Sparx about your child’s homework. If your child’s homework is complete, the ‘Current Homework’ progress bar will be green and say ‘100% complete’. The live homework summary will also show you whether your child needs to catch up on any previous homework.',
            },
          ],
        },
        {
          question: 'What happens if my child is unwell/off school?',
          body: [
            {
              answer:
                "You must tell the school and your child may be given extra time to complete that week's homework. If they were to be given this extra time, they would still have to complete their new homework assignment on time each week.",
            },
          ],
        },
      ],
      title: 'Late or missing homework',
    },
  ],
};
