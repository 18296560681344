import { Book } from '@sparx/api/apis/sparx/reading/books/v1/book';
import { GetStudentBooksRequest } from '@sparx/api/apis/sparx/reading/management/v1/management';
import {
  GetHomeworksRequest,
  GetStudentInfoRequest,
} from '@sparx/api/apis/sparx/reading/parentweb/v1/parentweb';
import { Homework } from '@sparx/api/apis/sparx/reading/tasks/v1/homework';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useQuery } from '@tanstack/react-query';
import { readerClient } from 'api';
import { isAfter, isBefore } from 'date-fns';

export const useGetStudentInfo = () =>
  useQuery(
    ['reader', 'get-user-info'],
    () => readerClient.getStudentInfo(GetStudentInfoRequest.create()).response,
    { select: data => data.user },
  );

export const useGetHomeworkMap = () =>
  useQuery(
    ['reader', 'get-homeworks'],
    () => readerClient.getHomeworks(GetHomeworksRequest.create()).response,
    {
      select: ({ homeworks }) => {
        const homeworkMap: {
          currentHomework: Homework | undefined;
          previousHomeworks: Homework[];
        } = {
          currentHomework: undefined,
          previousHomeworks: [],
        };
        for (const homework of homeworks) {
          if (!homework.startDate || !homework.endDate) {
            continue;
          }
          const start = Timestamp.toDate(homework.startDate);
          const end = Timestamp.toDate(homework.endDate);
          const now = new Date();
          if (isBefore(start, now) && isAfter(end, now)) {
            homeworkMap.currentHomework = homework;
          } else if (isBefore(end, now)) {
            homeworkMap.previousHomeworks.push(homework);
          }
        }
        homeworkMap.previousHomeworks.sort((a, b) =>
          a.endDate && b.endDate ? a.endDate?.nanos - b.endDate?.nanos : 0,
        );
        return homeworkMap;
      },
    },
  );

export const useGetStudentBooks = () =>
  useQuery(
    ['reader', 'get-student-books'],
    () => readerClient.getStudentBooks(GetStudentBooksRequest.create()).response,
    { select: data => data.books },
  );

export const getImageURL = (book: Book): string => {
  const imageSource = book.coverImage?.source;
  if (imageSource?.oneofKind !== 'name') {
    return '';
  }
  const bookName = book.name;
  const assetName = imageSource.name;

  return `${window.settings.READER_IMAGES_URL}/v2/${tidyBookName(bookName)}/${tidyAssetName(
    assetName,
  )}`;
};

const tidyBookName = (bookName: string): string => bookName.replace('books/', '');

const tidyAssetName = (assetName: string): string => {
  const split = assetName.split('/');
  // Expect "books/{book_id}/asset/{asset_id}"
  if (split.length !== 4) {
    console.warn('assetName incorrectly formatted', assetName);
    return assetName;
  }
  return split[split.length - 1]; // return final component (asset id)
};
