import moment from 'moment';
import { FunctionComponent } from 'react';

import styles from './copyright-notice.module.css';

export const CopyrightNotice: FunctionComponent = () => (
  <div className={styles.Container}>
    &#169;&nbsp;Sparx&nbsp;Limited&nbsp;2016-{moment().format('YYYY')}
  </div>
);
