import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Dialog from '@radix-ui/react-dialog';
import { useLibAnalytics } from '@sparx/analytics';
import { StudentBookAndMetadata } from '@sparx/api/apis/sparx/reading/management/v1/management';
import { Button } from '@sparx/sparx-design/components';
import { closedBookSummaryModal } from 'app/events';
import { ProgressBar } from 'components/progress-bar';
import { getImageURL } from 'queries/reader';
import { makeItemList } from 'utils/names';

import styles from './book-info-dialog.module.css';

type Props = {
  open: boolean;
  book: StudentBookAndMetadata;
  currentIndex: number;
  bookCount: number;
  isPreviousDisabled: boolean;
  isNextDisabled: boolean;
  onClose: () => void;
  onPreviousBook: () => void;
  onNextBook: () => void;
};

export const BookInfoDialog = ({
  open,
  book,
  currentIndex,
  bookCount,
  isPreviousDisabled,
  isNextDisabled,
  onClose,
  onPreviousBook,
  onNextBook,
}: Props) => {
  const sendEvent = useLibAnalytics();

  const percentComplete = (book.studentBook?.progress || 0) * 100;

  return (
    <Dialog.Root
      open={open}
      onOpenChange={open => {
        if (!open) {
          sendEvent(closedBookSummaryModal());
          onClose();
        }
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay className={styles.Overlay} />
        <Dialog.Content className={styles.Content}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Dialog.Title asChild>
              <div style={{ position: 'relative' }}>
                <h2 className={styles.Title}>Recently reading</h2>

                <div className={styles.ButtonCycleContainer}>
                  <Button
                    isDisabled={isPreviousDisabled}
                    onClick={() => onPreviousBook()}
                    size="sm"
                    variant="contained"
                  >
                    <FontAwesomeIcon icon={faAngleLeft} size="sm" />
                  </Button>
                  <div className={styles.Progress}>
                    {currentIndex + 1} / {bookCount}
                  </div>
                  <Button
                    isDisabled={isNextDisabled}
                    onClick={() => onNextBook()}
                    size="sm"
                    variant="contained"
                  >
                    <FontAwesomeIcon icon={faAngleRight} size="sm" />
                  </Button>
                </div>
              </div>
            </Dialog.Title>

            <div className={styles.ScrollContainer}>
              <div className={styles.MetadataContainer}>
                {book.metadata?.coverImage && (
                  <div className={styles.Cover}>
                    <img
                      src={getImageURL(book.metadata)}
                      alt={`${book.metadata.title} book cover`}
                    />
                  </div>
                )}
                <div className={styles.Metadata}>
                  <div style={{ flex: 1 }}>
                    <p className={styles.BookTitle}>{book.metadata?.title}</p>
                    {book.metadata?.authors.length && (
                      <p className={styles.BookAuthors}>
                        By {makeItemList(book.metadata?.authors)}
                      </p>
                    )}
                    <p className={styles.BookGenres}>{book.metadata?.genres.join(' • ')}</p>
                  </div>
                  <div>
                    <ProgressBar
                      percentComplete={percentComplete}
                      skinny={true}
                      tooltip={`${Math.round(percentComplete)}% read`}
                    />
                  </div>
                </div>
              </div>
              <p className={styles.DescriptionTitle}>Description:</p>
              <Dialog.Description
                className={styles.Description}
                dangerouslySetInnerHTML={{ __html: book.metadata?.description || '' }}
                style={{ flex: 1 }}
              />
            </div>
            <Dialog.Close asChild>
              <div className={styles.ButtonContainer}>
                <Button variant="contained">Close</Button>
              </div>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
